import { Colors } from '.'

const Theme = {
  // blue
  blue: Colors.blue[6],
  lightBlue: Colors.blue[5],
  darkBlue: Colors.blue[7],
  // green
  green: Colors.green[6],
  lightGreen: Colors.green[5],
  darkGreen: Colors.green[7],
  // yellow
  lightYellow: Colors.yellow[0],
  yellow: Colors.yellow[5],
  // red
  red: Colors.red[5],
  // orange
  darkOrange: Colors.orange[6],
  lightOrange: Colors.orange[4],
  orange: Colors.orange[5],
  // purple
  purple: Colors.purple[6],
  lightPurple: Colors.purple[5],
  darkPurple: Colors.purple[7],
  // neutral
  white: Colors.neutral[0],
  light: Colors.neutral[1],
  dark: Colors.neutral[9],
  // gray
  gray: Colors.neutral[4],
  lightGray: Colors.neutral[1],
  darkGray: Colors.neutral[7],

}
export default Theme