/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Input, Row, Typography, Button, Space, Table, Tag, message } from 'antd'
import { Link, useParams } from 'react-router-dom'
import Spacer from '../../components/layout/Spacer'
import warehouseAPI from '../../services/warehouseAPI'
import productAPI from '../../services/productAPI'

function LocationPage() {
  const tableColumns = [
    {
      title: 'Shelf Code',
      dataIndex: 'shelf',
      key: 'shelf',
      render: (val) => val.code
    },
    {
      title: 'Shelf Stock',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Rack',
      dataIndex: 'shelf',
      key: 'shelf',
      render: (val) => val.rack.name

    },
    {
      title: 'Warehouse Name',
      dataIndex: 'shelf',
      key: 'shelf',
      render: (val) => val.rack.warehouse.name

    }
  ];

  const routeParams = useParams()
  const [loading, setloading] = useState(false)
  const [productLocation, setProductLocation] = useState({
    data: [],
  })
  const [form] = Form.useForm()

  useEffect(() => {
    fetchData()
  }, [])


  const fetchData = async () => {
    setloading(true)

    const res = await productAPI.getLocation(routeParams.productId)
    const sum = res.data.product.shelves.reduce((prev, curr) => prev + parseInt(curr.quantity), 0)

    setProductLocation({
      data: res.data.product.shelves,
    })

    form.setFieldsValue({ totalStock: sum })
    form.setFieldsValue({ productName: res.data.product.name, productSku: res.data.product.sku, })

    setloading(false)
  }

  return (
    <div>
      <Typography.Title level={2}> Location</Typography.Title>
      <Card>

        <Row justify='space-between'>
          <Col md={24}>
            <Form layout='inline' form={form}>
              <Form.Item
                name="productName"
                label='Product Name'
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item
                name="productSku"
                label='SKU'
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item
                name="totalStock"
                label='Total Stock'
              >
                <Input readOnly />
              </Form.Item>
            </Form>
          </Col>


        </Row>

      </Card>
      <Spacer />
      <Card>
        <Table
          columns={tableColumns}
          // pagination={pagination}
          onChange={(pagination, filter, sorter) => fetchData(pagination)}
          dataSource={productLocation.data}
          loading={loading}
          rowKey='id' scroll={{ x: true }}
        />
      </Card>
    </div >
  )
}

export default LocationPage
