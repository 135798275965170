import { baseAxios } from "./axios"

const getAllPaginate = async (params = {}) => {
  const res = await baseAxios.get('/inbound', { params })
  return res.data
}

const getAllBatchPaginate = async (params = {}) => {
  const res = await baseAxios.get('/inbound/batch', { params })
  return res.data
}

const remove = async (id) => {
  const res = await baseAxios.delete('/inbound/' + id)
  return res.data
}

const cancel = async (id) => {
  const res = await baseAxios.put(`/inbound/${id}/cancel`)
  return res.data
}

const cancelBatch = async (id) => {
  const res = await baseAxios.put(`/inbound/batch/${id}/cancel`)
  return res.data
}

const create = async (body) => {
  const res = await baseAxios.post('/inbound/batch/import', body)
  return res.data
}
const printLabel = async (id) => {
  const res = await baseAxios.post(`/inbound/batch/print-label`, {
    id: id
  })
  return res.data
}
const approveBatch = async (id, body) => {
  const res = await baseAxios.put(`/inbound/batch/${id}/approve`, body)
  return res.data
}
const exports = async (params = {}) => {
  const res= await baseAxios.get('inbound/export-excel', {params})
  return res.data
}

const update = async (id, body) => {
  const res= await baseAxios.put(`/inbound/batch/${id}`, body)
  return res.data
}


export default { getAllPaginate, remove, cancel, cancelBatch, create, printLabel, getAllBatchPaginate, approveBatch, exports, update }
