/** @jsxImportSource @emotion/react */

import { DownOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Card, Col, Form, Select, Input, Row, Typography, Button, Menu, Dropdown, Table, Modal, Tag, message, Upload, Space, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Spacer from '../../components/layout/Spacer'
import ProductFormModal from '../../components/product/ProductFormModal'
import productAPI from '../../services/productAPI'

import config from '../../utils/config'
import TimeDetail from '../../components/common/TimeDetail'
import { INITIAL_PAGINATION } from '../../utils/constant'
import ProductImportModal from '../../components/product/ProductImportModal'
import FilterSection from '../../components/common/FilterSection'
import ProductCheckModal from '../../components/product/ProductCheckModal'
import ProductLocationModal from '../../components/product/ProductLocationModal'
import StockHistoryModal from '../../components/stock/StockHistoryModal'

function ProductPage() {
  const tableColumns = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value) => <b>{value}</b>
    },
    {
      title: 'Variant',
      dataIndex: 'color',
      key: 'color',
      render: (value, record) =>
      (
        <Space direction='vertical'>
          <small>
            <b>Size</b> <br /> {record.attribute ?? '-'}
          </small>
          <small>
            <b>Color</b> <br />{value}
          </small>
        </Space>
      )
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (value, record) => <span>{value.name}</span>
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      render: (value) => <b>{value.name}</b>
    },
    {
      title: 'Status',
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      render: (val) => val ? <Tag color='red'>Inactive</Tag> : <Tag color='green'>Active</Tag>
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value, record) => <TimeDetail createdAt={value} updatedAt={record.updatedAt} />
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) =>
        <Space direction="vertical">
          <Button type='link' size='small' onClick={() => selectData(record, 'create')}>Edit</Button>
          <Button type='link' size='small' onClick={() => deleteConfirm(value)}>Delete</Button>

          <Button type='link' onClick={() => selectData(record, 'location')} size='small'>Location</Button>

          <Button type='link' onClick={() => selectData(record, 'history')} size='small'>Stock History</Button>

        </Space>
    },
  ];

  const [createModal, setCreateModal] = useState(false)

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setCreateModal(true)}>
        Import File
      </Menu.Item>
      <a href={`${config.AWS_S3_URL}/template/adawms-product-template.xlsx`}>
        <Menu.Item key='2'>
          Download template file
        </Menu.Item>
      </a>

    </Menu>
  );

  const routeParams = useParams()

  const [modal, setModal] = useState(false)
  const [checkModal, setCheckModal] = useState(false)
  const [locModal, setLocModal] = useState(false)
  const [historyModal, setHistoryModal] = useState(false)

  const [loading, setloading] = useState(false)
  const [product, setProduct] = useState({
    data: [],
    selected: null,
  })

  const [pagination, setPagination] = useState(INITIAL_PAGINATION)

  const [filters, setFilters] = useState({});

  useEffect(() => {
    fetchData(INITIAL_PAGINATION, filters)
    setPagination(INITIAL_PAGINATION)
  }, [filters])

  const selectData = (record, modalName) => {
    setProduct({ ...product, selected: record })
    if (modalName == 'create') {
      setModal(true)
    }
    else if (modalName == 'location') {
      setLocModal(true)
    }
    else if (modalName == 'history') {
      setHistoryModal(true)
    }
  }

  const deleteConfirm = (id) => {
    Modal.confirm({
      title: 'Do you Want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone',
      onOk: async () => {
        const res = await productAPI.remove(id)
        message.success('Delete success')
        fetchData({ current: 1, pageSize: 10 })
      },
    });
  }

  const fetchData = async (pagination, filters) => {
    setloading(true)
    const params = {
      ...filters,
      page: pagination.current,
      limit: pagination.pageSize,
      includes: ['category', 'brand']
    }
    const res = await productAPI.getAllPaginate(params)
    setProduct({
      data: res.data.product.items,
      selected: null,
    })
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: res.data.product.meta.totalItems
    })
    setloading(false)
  }

  const submitFilter = (value) => {
    const data = {
      ...value,
    }
    setFilters(data)
  }

  const exportProduct = async () => {
    setloading(true)
    let params = {
      ...filters,
    };
    const res = await productAPI.exports(params)
    window.location.href = res.data.product.Location
    setloading(false)
  }

  const exportProductLocation = async () => {
    setloading(true)
    let params = {
      ...filters,
    };
    const res = await productAPI.exportLocation(params)
    window.location.href = res.data.product.Location
    setloading(false)
  }

  return (
    <div>
      <Typography.Title level={2}>Product</Typography.Title>
      <Card
        title='List of Product'
        extra={
          <Space>
            <Button type="primary" loading={loading} onClick={() => setCheckModal(true)}>
              Check
            </Button>

            <Dropdown overlay={
              (
                <Menu>
                  <Menu.Item key="1" onClick={() => exportProduct(filters)}>
                    Export Product
                  </Menu.Item>

                  <Menu.Item key="2" onClick={() => exportProductLocation(filters)}>
                    Export Location
                  </Menu.Item>


                </Menu>
              )}>
              <Button type='primary' loading={loading}>
                Export<DownOutlined />
              </Button>
            </Dropdown>

            <Dropdown overlay={menu}>
              <Button type='primary' loading={loading}>
                Create Product<DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        }
      >
        <FilterSection byProduct byWarehouse byBrand byCategory onSubmit={submitFilter} />

      </Card>
      <Spacer />
      <Card>
        <Table
          columns={tableColumns}
          pagination={pagination}
          onChange={(pagination) => fetchData(pagination, filters)}
          dataSource={product.data}
          loading={loading}
          rowKey='id' scroll={{ x: true }}
        />
      </Card>

      <ProductFormModal
        visible={modal}
        data={product.selected}
        onCancel={() => {
          setModal(false)
          setProduct({ ...product, selected: null })
        }}
        onFinish={() => {
          setModal(false)
          fetchData(pagination)
        }}
        warehouseId={routeParams.warehouseId}
      />
      <ProductImportModal
        onCancel={() => {
          setCreateModal(false)
        }}
        onFinish={() => {
          setCreateModal(false)
          fetchData(pagination)

        }}
        visible={createModal}
      />
      <ProductCheckModal visible={checkModal} onCancel={() => setCheckModal(false)} />
      <ProductLocationModal
        visible={locModal}
        onCancel={() => {
          setLocModal(false)
          setProduct({ ...product, selected: null })
        }}
        data={product.selected}
      />
      <StockHistoryModal
        visible={historyModal}
        onCancel={() => {
          setHistoryModal(false)
          setProduct({ ...product, selected: null })
        }}
        data={product.selected}
      />
    </div>
  )
}
export default ProductPage