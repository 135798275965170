import { baseAxios } from "./axios"


const login = async (body) => {
  const res = await baseAxios.post('/user/login', body)
  return res.data
}

const register = async (body) => {
  const res = await baseAxios.post('/user/register', body)
  return res.data
}

const getAllStaffPaginate = async (params = {}) => {
  const res = await baseAxios.get('/user/staff', { params })
  return res.data
}

const createStaff = async (body) => {
  const res = await baseAxios.post('/user/staff', body)
  return res.data
}

const updateStaff = async (id, body) => {
  const res = await baseAxios.put(`/user/staff/${id}`, body)
  return res.data
}

const deleteStaff = async (id) => {
  const res = await baseAxios.delete(`/user/staff/${id}`)
  return res.data
}

export default { login, register, createStaff, updateStaff, deleteStaff, getAllStaffPaginate }