const Width = {
  w100: {
    width: '100%'
  },
  w75: {
    width: '75%'
  },
  w50: {
    width: '50%'
  },
  w25: {
    width: '25%'
  },
  w10: {
    width: '10%'
  }
}
export default Width