/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Input, Row, Typography, Button, Space, Table, Tag, message, Modal } from 'antd'
import { Link, useParams } from 'react-router-dom'
import Spacer from '../../components/layout/Spacer'
import warehouseAPI from '../../services/warehouseAPI'
import productAPI from '../../services/productAPI'

function ProductLocationModal({ visible, onCancel, data }) {
  const tableColumns = [
    {
      title: 'Shelf Code',
      dataIndex: 'shelf',
      key: 'shelf',
      render: (val) => val.code
    },
    {
      title: 'Shelf Stock',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Rack',
      dataIndex: 'shelf',
      key: 'shelf',
      render: (val) => val.rack.name

    },
    {
      title: 'Warehouse Name',
      dataIndex: 'shelf',
      key: 'shelf',
      render: (val) => val.rack.warehouse.name

    }
  ];

  const [loading, setloading] = useState(false)
  const [productLocation, setProductLocation] = useState({
    data: [],
  })
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    if (data) {
      fetchData()
    }
  }, [data, visible])

  const fetchData = async () => {
    setloading(true)

    const res = await productAPI.getLocation(data.id)
    const sum = res.data.product.shelves.reduce((prev, curr) => prev + parseInt(curr.quantity), 0)

    setProductLocation({
      data: res.data.product.shelves,
    })

    form.setFieldsValue({ totalStock: sum })
    form.setFieldsValue({ id: res.data.product.id, productName: res.data.product.name, productSku: res.data.product.sku, })

    setloading(false)
  }

  return (
    <Modal width={1200} title={`Location - ${data?.name} `} visible={visible} onCancel={onCancel} footer={null} >
      <Row justify='space-between'>
        <Col md={24}>
          <Form layout='inline' form={form}>
            <Form.Item
              name="id"
              label='Product ID'
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name="productName"
              label='Product Name'
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name="productSku"
              label='SKU'
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name="totalStock"
              label='Total Stock'
            >
              <Input readOnly />
            </Form.Item>
          </Form>
        </Col>


      </Row>

      <Spacer />
      <Table
        columns={tableColumns}
        // pagination={pagination}
        onChange={(pagination, filter, sorter) => fetchData(pagination)}
        dataSource={productLocation.data}
        loading={loading}
        rowKey='id' scroll={{ x: true }}
      />
    </Modal >
  )
}

export default ProductLocationModal
