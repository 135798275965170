import { baseAxios } from "./axios"

const getAllPaginate = async (params = {}) => {
  const res = await baseAxios.get('/outbound', { params })
  return res.data
}

const getDetail = async (params = {}) => {
  const res = await baseAxios.get(`/outbound/detail/`, { params })
  return res.data
}

const getStatusHistory = async (id) => {
  const res = await baseAxios.get('/outbound/' + id)
  return res.data
}

const remove = async (id) => {
  const res = await baseAxios.delete('/outbound/' + id)
  return res.data
}

const cancel = async (id) => {
  const res = await baseAxios.put(`/outbound/${id}/cancel`)
  return res.data
}

const approve = async (id) => {
  const res = await baseAxios.put('/outbound/approve', {
    outboundId: id
  })
  return res.data
}

const create = async (body) => {
  const res = await baseAxios.post('/outbound/import', body)
  return res.data
}

// * OUTBOUND BATCH
const approveBatch = async (id) => {
  const res = await baseAxios.put('/outbound-batch/approve', {
    batchId: id
  })
  return res.data
}

const printLabel = async (val) => {
  const res = await baseAxios.post(`/outbound-batch/print-label`, val)
  return res.data
}

const getAllBatchPaginate = async (params = {}) => {
  const res = await baseAxios.get('/outbound-batch', { params })
  return res.data
}

const getOneBatch = async (id) => {
  const res = await baseAxios.get('/outbound-batch/detail/' + id)
  return res.data
}

const cancelBatch = async (id) => {
  const res = await baseAxios.put(`/outbound-batch/${id}/cancel`)
  return res.data
}

const exports = async (params = {}) => {
  const res = await baseAxios.get('/outbound/export-excel', { params })
  return res.data
}

const bulkUpdate = async (body) => {
  const res = await baseAxios.put('/outbound/bulk-update', body)
  return res.data
}

const printHandoverReport = async (body) => {
  const res = await baseAxios.post('/outbound/print/handover-report', body)
  return res.data
}

const getAllShippedHistory = async () => {
  const res = await baseAxios.get('/outbound/history/shipped')
  return res.data
}



export default {
  bulkUpdate,
  getAllPaginate,
  remove,
  getStatusHistory,
  cancel,
  approve,
  approveBatch,
  create,
  printLabel,
  getAllBatchPaginate,
  getOneBatch,
  cancelBatch,
  getDetail,
  exports,
  printHandoverReport,
  getAllShippedHistory
}
