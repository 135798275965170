import adawmsLogo from './adawms_logo.png'
import tiktok from './channel/tiktok.png'
import tokopedia from './channel/tokped.png'
import shopee from './channel/shopee.png'
import lazada from './channel/lazada.png'
import tags from './channel/tags.png'
import warehouse from './warehouse.png'

export default {
  adawmsLogo,
  tiktok,
  tokopedia,
  lazada,
  tags,
  shopee,
  warehouse
}