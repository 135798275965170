import { Modal, Form, Input, Switch, message, Row, Space, Button, Select, Typography, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import productAPI from '../../services/productAPI'
import productBrandAPI from '../../services/productBrandAPI'
import warehouseAPI from '../../services/warehouseAPI'
import productCategoryAPI from '../../services/productCategoryAPI'
import Spacer from '../layout/Spacer'
import { COLOR_LIST } from '../../utils/constant'
import { cloneDeep } from 'lodash'
function ProductFormModal({ visible, onCancel, data, onFinish }) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState({
    data: [],
    selected: null
  })
  const [brand, setBrand] = useState([])

  useEffect(() => {
    async function editProduct() {
      form.resetFields()
      await fetchCategory()
      await fetchBrand()
      if (data) {
        form.setFieldsValue(data)
        setCategory({ ...category, selected: data.category })
      }
    }
    editProduct()
  }, [data, visible])

  const submitForm = async (value) => {
    setLoading(true)
    try {
      if (data) {
        await productAPI.update(data.id, value)
      }
      else {
        await productAPI.create(value)
      }
      message.success('success')
    } catch (error) {
      message.error('something wrong')
    }
    setLoading(false)
    onFinish()
  }

  const fetchBrand = async () => {
    setLoading(true)
    try {
      const res = await productBrandAPI.getAllPaginate({
        page: 1,
        limit: 1000,
      })
      setBrand(res.data.productBrand.items)
    } catch (error) {
      message.error('error fetch brand')
    }
    setLoading(false)
  }

  const fetchCategory = async () => {
    setLoading(true)
    try {
      const res = await productCategoryAPI.getAllPaginate({
        page: 1,
        limit: 1000,
      })
      setCategory({ ...category, data: res.data.productCategory.items })

    } catch (error) {
      message.error('error fetch category')
    }
    setLoading(false)
  }

  const changeCategory = (id) => {
    const selectedCategory = category.data.find(item => item.id === id)
    setCategory({ ...category, selected: selectedCategory })
  }

  return (
    <Modal title={data ? 'Edit Product' : 'Create Product'} maskClosable={false} visible={visible} footer={false} onCancel={onCancel}>
      <Form
        form={form}
        layout='vertical'
        onFinish={submitForm}
        autoComplete="off"
      >

        <Form.Item
          label="Brand"
          name="brandId"
          rules={[{ required: true }]}
          initialValue={data?.brand.id}
        >
          <Select>
            {brand.map(item =>
              <Select.Option value={item.id}>
                {item.name}
              </Select.Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          label="Product Category"
          name="categoryId"
          rules={[{ required: true }]}
          initialValue={data?.category.id}
        >
          <Select
            onChange={changeCategory}
          >
            {category.data?.map(item =>
              <Select.Option value={item.id}>
                {item.name}
              </Select.Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="SKU"
          name="sku"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Product Attribute (Size)"
          name="attribute"
          rules={[{ required: true }]}
        >
          <Select>
            {category.selected?.attributes?.map(item =>
              <Select.Option value={item.name}>
                {item.name}
              </Select.Option>
            )}
          </Select>
        </Form.Item>


        <Form.Item
          label="Color"
          name="color"
          rules={[{ required: true }]}
        >
          <Select>
            {COLOR_LIST.map(item =>
              <Select.Option value={item}>
                {item}
              </Select.Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          required
        >
          <Input type='number' min={1} prefix='Rp' />
        </Form.Item>
        <Typography.Title level={5}>Dimension</Typography.Title>
        <Spacer border />
        <Form.List name='dimension'>
          {() => (
            <Row gutter={8}>
              <Col md={12}>
                <Form.Item
                  label="Weight"
                  name="weight"
                  required
                >
                  <Input type='number' min={1} suffix='gram' />
                </Form.Item>

              </Col>
              <Col md={12}>
                <Form.Item
                  label="Length"
                  name="length"
                  required
                >
                  <Input type='number' min={1} suffix='cm' />
                </Form.Item>
              </Col>

              <Col md={12}>
                <Form.Item
                  label="Width"
                  name="width"
                  required
                >
                  <Input type='number' min={1} suffix='cm' />

                </Form.Item>
              </Col>

              <Col md={12}>
                <Form.Item
                  label="Height"
                  name="height"
                  required
                >
                  <Input autoComplete='off' min={1} suffix='cm' />
                </Form.Item>
              </Col>

            </Row>
          )}

        </Form.List>

        <Row justify='end'>
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType='submit' loading={loading} type='primary' >Save</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default ProductFormModal
