import { baseAxios } from "./axios"


const create = async (body) => {
  const res = await baseAxios.post('/warehouse', body)
  return res.data
}

const getAllPaginate = async (params = {}) => {
  const res = await baseAxios.get('/warehouse', { params })
  return res.data
}

const update = async (id, body) => {
  const res = await baseAxios.put('/warehouse/' + id, body)
  return res.data
}

const getRacks = async (id, params = {}) => {
  const res = await baseAxios.get(`/warehouse/${id}/rack`, { params })
  return res.data
}

const remove = async (id) => {
  const res = await baseAxios.delete('/warehouse/' + id)
  return res.data
}

export default { create, update, getAllPaginate, getRacks, remove }