/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Select,
  Input,
  Row,
  Typography,
  Button,
  Space,
  Table,
  Col
} from "antd";
import { Link } from 'react-router-dom'
import Spacer from "../../components/layout/Spacer";
import { SearchOutlined } from "@ant-design/icons";
import warehouseAPI from "../../services/warehouseAPI";
import stockAPI from "../../services/stockAPI"
import EditStockModal from "../../components/stock/EditStockModal"
import { INITIAL_PAGINATION } from "../../utils/constant";
import FilterSection from "../../components/common/FilterSection";
import ProductLocationModal from "../../components/product/ProductLocationModal";
import StockHistoryModal from "../../components/stock/StockHistoryModal";

function StockPage() {
  const tableColumns = [
    {
      title: "Product Name",
      dataIndex: "product",
      key: "product",
      render: (value) => <b>{value.name} - {value.attribute}</b>
    },
    {
      title: "SKU",
      dataIndex: "product",
      key: "product",
      render: (value) => <span>{value.sku}</span>
    },
    {
      title: "Pending Stock",
      dataIndex: "pendingStock",
      key: "pendingStock",
    },
    {
      title: "Available Stock",
      dataIndex: "availableStock",
      key: "availableStock",
    },
    {
      title: "Locked Stock",
      dataIndex: "lockedStock",
      key: "lockedStock",
    },
    {
      title: "Shipped Stock",
      dataIndex: "shippedStock",
      key: "shippedStock",
    },
    {
      title: "Warehouse Name",
      dataIndex: "warehouse",
      key: "warehouse",
      render: (value) => <b>{value.name}</b>
    },
    {
      title: "Action",
      dataIndex: "product",
      key: "product",
      render: (value, record) => (
        <Space direction="vertical">
          <Button type="link" onClick={() => selectData(record, 'create')} size="small" >
            Edit
          </Button>

          <Button type='link' onClick={() => selectData(record, 'location')} size='small'>Location</Button>

          <Button type='link' onClick={() => selectData(record, 'history')} size='small'>Stock History</Button>
        </Space>
      ),
    },
  ];

  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false);

  const [stock, setStock] = useState({
    data: [],
    selected: null
  })

  const [createModal, setCreateModal] = useState(false)
  const [locModal, setLocModal] = useState(false)
  const [historyModal, setHistoryModal] = useState(false)
  const [pagination, setPagination] = useState(INITIAL_PAGINATION);
  const [filters, setFilters] = useState({});

  const fetchStock = async (pagination, filters) => {
    setLoading(true)
    const params = {
      ...filters,
      page: pagination.current,
      limit: pagination.pageSize,
    }
    const res = await stockAPI.getAllPaginate(params)
    setStock({
      data: res.data.stocks.items,
      selected: null,
    })
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: res.data.stocks.meta.totalItems
    })
    setLoading(false)
  }

  const selectData = (record, modalName) => {
    setStock({ ...stock, selected: record })
    if (modalName == 'create') {
      setCreateModal(true)
    }
    else if (modalName == 'location') {
      setLocModal(true)
    }
    else if (modalName == 'history') {
      setHistoryModal(true)
    }
  }

  const exportReportStock = async (filters) => {
    setLoading(true)
    let params = {
      ...filters,
    };
    const res = await stockAPI.exportStockReport(params)
    window.location.href = res.data.stocks.Location
    setLoading(false)
  }

  const submitFilter = (value) => {
    const data = {
      ...value
    }
    setFilters(data)
  };

  useEffect(() => {
    fetchStock(INITIAL_PAGINATION, filters)
    setPagination(INITIAL_PAGINATION)
  }, [filters]);

  useEffect(() => {
    form.resetFields()
  }, [])

  return (
    <div>
      <Typography.Title level={2}>Stock Management</Typography.Title>
      <Card
        title="List of Stock"
        extra={
          <Button type="primary" loading={loading} onClick={() => exportReportStock(filters)}>
            Export
          </Button>
        }
      >
        <FilterSection byProduct byWarehouse byBrand byCategory onSubmit={submitFilter} />

      </Card>
      <Spacer />
      <Card>
        <Table
          columns={tableColumns}
          pagination={pagination}
          onChange={(pagination, _filter, _sorter) => fetchStock(pagination, filters)}
          dataSource={stock.data}
          loading={loading}
          scroll={{ x: true }}
        />
      </Card>
      <EditStockModal
        visible={createModal}
        data={stock.selected}
        onCancel={() => {
          setCreateModal(false)
          setStock({ ...stock, selected: null })
        }}
        onFinish={() => {
          setStock({ ...stock, selected: null })
          setCreateModal(false)
          fetchStock(pagination, filters)
        }}
      />
      <ProductLocationModal
        visible={locModal}
        onCancel={() => {
          setLocModal(false)
          setStock({ ...stock, selected: null })
        }}
        data={stock.selected?.product}
      />
      <StockHistoryModal
        visible={historyModal}
        onCancel={() => {
          setHistoryModal(false)
          setStock({ ...stock, selected: null })
        }}
        data={stock.selected?.product}
      />
    </div>
  );
}
export default StockPage;