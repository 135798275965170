import { Modal, Form, Input, Switch, message, Row, Space, Button, Select, Typography, Col, notification, Upload, Descriptions, Table, Divider, Tabs, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import productAPI from '../../services/productAPI'
import productBrandAPI from '../../services/productBrandAPI'
import { useSelector } from 'react-redux'
import config from '../../utils/config'
import { CheckOutlined, DeleteOutlined, PrinterOutlined, UploadOutlined } from '@ant-design/icons'
import Spacer from '../layout/Spacer'
import { currencyFormat, utcToLocal } from '../../utils/helper'
import outboundAPI from '../../services/outboundAPI'
import OutboundStatusTag from '../common/OutboundStatusTag'
import { OUTBOUND_STATUS } from '../../utils/constant'
import warehouseAPI from '../../services/warehouseAPI'

function OutboundToShippedModal({ visible, onCancel, data, onFinish }) {


  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Customer Name',
      dataIndex: 'delivery',
      key: 'delivery',
      render: (value) => value.recipientName
    },
    {
      title: 'Order Number',
      dataIndex: 'externalId',
      key: 'externalId',
    },
    {
      title: 'Receipt number',
      dataIndex: 'delivery',
      key: 'delivery',
      render: (value) => value.receiptNumber
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => <OutboundStatusTag status={value} />

    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (value) => <Button onClick={() => removeItem(value)} type='link'>Remove</Button>

    },
  ]

  const historyTableColumns = [

    {
      title: 'Total Outbound',
      dataIndex: 'outboundIds',
      key: 'outboundIds',
      render: (value) => value.length
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: "Printed",
      dataIndex: "printed",
      key: "printed",
      render: (value, record) => value ? <Tag color='green'>Printed</Tag> : <Tag color='red'>Not Printed</Tag>
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => utcToLocal(value)
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (value) => (selectedHistory != value ?
        <Button onClick={() => {
          setSelectedHistory(value)
        }} type='link'>Select</Button>
        : <Button type='primary'>Selected <CheckOutlined /></Button>
      )

    },
  ]
  const [form] = Form.useForm()
  const [value, setValue] = useState(null)
  const [loading, setLoading] = useState(false)
  const [outbound, setOutbound] = useState([])
  const [warehouse, setWarehouse] = useState([])
  const [shippedHistory, setShippedHistory] = useState([])
  const [selectedHistory, setSelectedHistory] = useState([])

  const onChangeHandler = async (e) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    fetchWarehouse()
    fetchShippedHistory()
  }, [])
  const onEnter = async (e) => {
    setLoading(true)
    if (e.key === 'Enter') {
      try {
        const res = await outboundAPI.getDetail({ code: value })
        console.log(!outbound.some(i => i.id == res.data.outbound.id));
        if (!outbound.some(i => i.id == res.data.outbound.id)) {
          setOutbound([res.data.outbound, ...outbound])
          message.success('Outbound found')
        } else {
          message.success('Outbound already scanned')

        }

      } catch (error) {
        message.error('Outbound not found')
      }
      form.resetFields()
      setValue('')
    }
    setLoading(false)

  }

  const exportData = async () => {
    const formVal = form.getFieldsValue()
    setLoading(true)
    try {
      const res = await outboundAPI.printHandoverReport({ id: selectedHistory, ...formVal })
      window.open(res.data.Location, '_blank');
    } catch (error) {
      message.error('Export failed')
    }
    setLoading(false)
  }

  const updateData = async () => {
    setLoading(true)
    try {
      const res = await outboundAPI.bulkUpdate({
        status: OUTBOUND_STATUS.SHIPPED,
        outboundCodes: outbound.flatMap(i => i.delivery.receiptNumber)
      })
      const updated = outbound.map(i => {
        if (res.data.success.includes(i.id)) {
          return {
            ...i,
            status: OUTBOUND_STATUS.SHIPPED
          }
        }
        return i
      })

      setOutbound(updated)
      message.success('Update Success')
    } catch (error) {
      message.error('Update failed')
    }
    setLoading(false)
  }

  const removeItem = (id) => {
    const removed = outbound.filter(i => i.id != id)
    setOutbound(removed)
  }
  const fetchWarehouse = async () => {
    const params = {
      page: 1,
      limit: 1000,
    }
    const res = await warehouseAPI.getAllPaginate(params)
    setWarehouse(res.data.warehouse.items)
  }
  const fetchShippedHistory = async () => {
    const res = await outboundAPI.getAllShippedHistory()
    setShippedHistory(res.data.histories)
  }
  return (
    <Modal
      width={980} maskClosable={false} visible={visible} footer={false} onCancel={onCancel}>
      <Tabs onChange={activeKey => activeKey == 'item-2' ? fetchShippedHistory() : null}>
        <Tabs.TabPane tab="Update to Shipped " key="item-1">
          <h3>Outbound List</h3>
          <Spacer />
          <Input value={value} addonBefore='Scan Here' placeholder='click here to scan...' autoFocus={true} rows={2} onChange={onChangeHandler} onKeyUp={onEnter} />
          <Divider />
          <Table
            columns={tableColumns}
            pagination={false}
            dataSource={outbound}
            loading={loading}
            // rowKey='id'
            scroll={{ y: 320, x: 400 }}
          />
          <Row justify='end'>
            <Space>
              <Button onClick={() => setOutbound([])} loading={loading}>Clear</Button>
              <Button onClick={updateData} type='primary' loading={loading}>Update to Shipped</Button>
            </Space>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Export Report" key="item-2">
          <h3>Handover Report Detail</h3>
          <Form
            form={form}
            layout='vertical'
            autoComplete="off"
          >
            <Row gutter={8}>
              <Col md={12}>

                <Form.Item
                  label="Sender"
                  name="sender"
                >
                  <Input />

                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Receiver"
                  name="receiver"
                >
                  <Input />

                </Form.Item>

              </Col>
              <Col md={12}>

                <Form.Item
                  label="Courier Name"
                  name="courierName"
                >
                  <Select>
                    <Select.Option value='sicepat'>Si Cepat</Select.Option>
                    <Select.Option value='j&t'>J&T</Select.Option>
                    <Select.Option value='ninja'>Ninja</Select.Option>
                    <Select.Option value='jne'>JNE</Select.Option>
                  </Select>

                </Form.Item>
              </Col>
              <Col md={12}>

                <Form.Item name="warehouseName" label="Warehouse">
                  <Select placeholder="Select Warehouse">
                    {warehouse.map((item) => (
                      <Select.Option value={item.name.toUpperCase()}>{item.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

              </Col>

            </Row>
          </Form>

          <Spacer />
          <h3>Outbound Shipped History</h3>
          <Table
            columns={historyTableColumns}
            pagination={false}
            dataSource={shippedHistory}
            loading={loading}
            // rowKey='id'
            scroll={{ y: 320, x: 400 }}
          />
          <Row justify='end'>
            <Space>
              <Button onClick={exportData} type='primary' loading={loading}><PrinterOutlined /> Export</Button>
            </Space>
          </Row>

        </Tabs.TabPane>
      </Tabs>;

    </Modal>
  )
}

export default OutboundToShippedModal
