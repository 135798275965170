import { baseAxios } from "./axios"

const getAllRolePaginate = async (params = {}) => {
  const res = await baseAxios.get('/role', { params })
  return res.data
}

const createRole = async (body) => {
  const res = await baseAxios.post('/role', body)
  return res.data
}

const updateRole = async (id, body) => {
  const res = await baseAxios.put(`/role/${id}`, body)
  return res.data
}

const deleteRole = async (id) => {
  const res = await baseAxios.delete(`/role/${id}`)
  return res.data
}

export default { getAllRolePaginate, createRole, updateRole, deleteRole }