import { Modal, Form, Input, message, Row, Space, Button } from "antd";
import React, { useEffect, useState } from "react";
import roleAPI from "../../services/roleAPI";

function RoleFormModal({ visible, onCancel, data, onFinish }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);


  const submitForm = async (value) => {
    setloading(true);
    try {
      if (data) {
        await roleAPI.updateRole(data.id, value);
      } else {
        await roleAPI.createRole(value);
      }
      message.success("success");
    } catch (error) {
      message.error("something wrong");
    }
    setloading(false);
    onFinish();
  };

  useEffect(() => {
    form.resetFields();
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data, visible]);

  return (
    <Modal
      title={data ? "Edit Role" : "Add Role"}
      visible={visible}
      footer={false}
      onCancel={onCancel}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={submitForm}
        autoComplete="off"
      >
        <Form.Item label="Role Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Row justify="end">
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType="submit" loading={loading} type="primary">
              Save
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
}

export default RoleFormModal;
