import { CheckOutlined, InfoCircleOutlined, InfoOutlined, WarningOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import React from 'react'
import { OUTBOUND_STATUS } from '../../utils/constant'

function OutboundBatchStatusTag({ status, outbounds }) {
  const processed = outbounds.filter(i => ![OUTBOUND_STATUS.CANCELED, OUTBOUND_STATUS.UNDERSTOCK].includes(i.status)).length
  const all = outbounds.filter(i => OUTBOUND_STATUS.CANCELED != i.status).length

  let percentage = Math.round((processed / all) * 100)
  // percentage = percentage == 0 ? 100 : percentage
  return (
    status === 'pending' ? <Tag color='blue' icon={<WarningOutlined />}>Pending</Tag> :
      status === 'approved' && percentage < 100 ? <Tag color='lime' icon={<InfoCircleOutlined />}>{percentage}% Processed</Tag> :
        status === 'approved' && percentage == 100 ? <Tag color='green' icon={<CheckOutlined />}>{percentage}% Processed</Tag> :

          <Tag color='red'>Canceled</Tag>
  )
}

export default OutboundBatchStatusTag
