import { UploadOutlined } from '@ant-design/icons'
import { Modal, Form, Input, Switch, message, Row, Space, Button, Select, Upload, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import outboundAPI from '../../services/outboundAPI'
import productBrandAPI from '../../services/productBrandAPI'
import warehouseAPI from '../../services/warehouseAPI'
import config from '../../utils/config'

function OutboundFormModal({ visible, onCancel, onFinish, brand }) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [warehouse, setWarehouse] = useState([])
  const auth = useSelector(state => state.auth)

  useEffect(() => {
    form.resetFields()
    fetchWarehouse()
  }, [visible])

  const submitForm = async (value) => {
    setLoading(true)
    const file = {
      name: value.files[0].response.data.Key,
      url: value.files[0].response.data.Location
    }
    const body = { ...value, file }
    try {
      const res = await outboundAPI.create(body)
      notification.info({
        duration: null,
        message: 'Import Done',
        placement: 'top',
        description: (
          <>
            <b>Success:</b>
            <p>{res.data.outbound.success ?? 0} outbound created</p>
            <b>Understock:</b>
            <p>
              {res.data.outbound.understock} outbounds
            </p>
            <b>Failed:</b>
            <ul style={{ overflowY: 'scroll', maxHeight: '15vh' }}>
              {
                res.data.outbound.failed.map(item => (
                  <li>{item}</li>
                ))
              }
            </ul>
          </>
        )
      })
      onFinish()
    } catch (error) {
      notification.error({
        message: "Outbound Failed",
        placement: 'top',
        duration: 10,
        description: error.response?.data?.message ?? 'Something went wrong, please try again later'
      })
    }
    finally {
      setLoading(false)

    }
  }

  const fetchWarehouse = async () => {
    setLoading(true)
    try {
      const res = await warehouseAPI.getAllPaginate({
        page: 1,
        limit: 1000,
      })
      setWarehouse(res.data.warehouse.items)
    } catch (error) {
      message.error('error fetch warehouse')
    }
    setLoading(false)
  }

  const onChangeUpload = ({ fileList, file }) => {
    if (file.status == 'uploading') {
      setLoading(true)
    }
    else if (file.status == 'done') {
      setLoading(false)
    }
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return (
    <Modal title='Create Outbound' visible={visible} maskClosable={false} footer={false} onCancel={onCancel}>
      <Form
        form={form}
        layout='vertical'
        onFinish={submitForm}
        autoComplete="off"
      >

        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true }]}
          initialValue='new'
        >
          <Select>
            <Select.Option value='new'>
              New
            </Select.Option>
            <Select.Option value='retur'>
              Retur
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="File Source"
          name="fileSource"
          rules={[{ required: true }]}
          initialValue='adawms'
        >
          <Select>
            <Select.Option value='adawms'>
              Adawms
            </Select.Option>
            <Select.Option value='ginee'>
              Ginee
            </Select.Option>
            <Select.Option value='desty'>
              Desty
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Brand"
          name="brandId"
          rules={[{ required: true }]}
        >
          <Select>
            {brand.map(item =>
              <Select.Option value={item.id}>
                {item.name}
              </Select.Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          label="Warehouse"
          name="warehouseId"
          rules={[{ required: true }]}
        >
          <Select>
            {warehouse.map(item =>
              <Select.Option value={item.id}>
                {item.name}
              </Select.Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          name='files'
          label='File'
          getValueFromEvent={normFile}
          valuePropName="fileList"
          rules={[{ required: true }]}
        >
          <Upload
            maxCount={1}
            action={config.API_URL + '/file/upload?folder=outbound'}
            headers={{ Authorization: 'Bearer ' + auth.token }}
            type='drag'
            onChange={onChangeUpload}>
            <UploadOutlined /> Click or drag files to this area to upload
          </Upload>
        </Form.Item>
        <Button href={`${config.AWS_S3_URL}/template/adawms-outbound-template.xlsx`}>
          Download Template
        </Button>
        <Row justify='end'>
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType='submit' loading={loading} type='primary' >Save</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default OutboundFormModal
