import { Modal, Form, Input, Switch, message, Row, Space, Button, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import rackAPI from '../../services/rackAPI'
import userAPI from '../../services/userAPI'

function RackFormModal({ visible, onCancel, data, onFinish, warehouseId }) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    form.resetFields()
    if (data) {
      form.setFieldsValue(data)
    }
  }, [data, visible])

  const submitForm = async (value) => {
    setLoading(true)
    const body = { ...value, warehouseId: warehouseId }
    try {
      if (data) {
        await rackAPI.update(data.id, body)
      }
      else {
        await rackAPI.create(body)
      }
      message.success('success')
    } catch (error) {
      message.error('something wrong')
    }
    setLoading(false)
    onFinish()
  }


  return (
    <Modal title={data ? 'Edit Rack' : 'Create Rack'} maskClosable={false} visible={visible} footer={false} onCancel={onCancel}>
      <Form
        form={form}
        layout='vertical'
        onFinish={submitForm}
        autoComplete="off"
      >
        <Form.Item
          label="Rack Code"
          name="code"
          extra="It will be auto generated"
        >
          <Input readOnly />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Row justify='end'>
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType='submit' loading={loading} type='primary' >Save</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default RackFormModal
