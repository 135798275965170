import Width from './utils/Width';
import Padding from './utils/Padding';
import Margin from './utils/Margin';
import Size from './utils/Size';
import Theme from './Theme';

const Styles = {
  ...Margin,
  ...Padding,
  ...Width,
  textCenter: {
    textAlign: 'center'
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  bottomBorder: {
    borderBottom: '1px solid ' + Theme.lightGray,
    ...Margin.mb1
  },
  block: {
    display: 'block'
  },
  boxShadow: {
    borderRadius: Size.sm,
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.1)'
  },
  boxRadius: {
    borderRadius: Size.sm
  },
  boxBorder: {
    border: '1px solid ' + Theme.lightGray
  },
  boxTopRadius: {
    borderTopLeftRadius: Size.sm,
    borderTopRightRadius: Size.sm,
  },
  mobile: {
    flexColumn: {
      flexDirection: 'column !important'
    },
    horizontalScroll: {
      '@media (max-width: 768px)': {
        flexFlow: 'row',
        overflowX: 'scroll',
      },
    },
    block: {
      '@media (max-width: 768px)': {
        width: '100%'
      },
    },
    hide: {
      '@media (max-width: 768px)': {
        display: 'none',
      }
    },
    show: {
      '@media (min-width: 769px)': {
        display: 'none',
      }
    }
  },
  skeleton: {
    height: '70vh',
    width: '100%',
    background: Theme.gray,
    borderRadius: Size.sm
  },
  logoIcon: {
    // height: '32px',
    width: '14px',
  }
}


export default Styles

