import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  warehouses: [],
  primaryWarehouse: null
}

export const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    setWarehouses: (state, action) => {
      state.warehouses = action.payload.warehouses
      state.primaryWarehouse = action.payload.warehouses.find(item => item.isPrimary)
    },
  },
})

export const { setWarehouses } = warehouseSlice.actions

export default warehouseSlice.reducer