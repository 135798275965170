import { Space } from 'antd'
import React from 'react'
import { utcToLocal } from '../../utils/helper'

const TimeDetail = ({ createdAt, updatedAt }) => {
  return (
    <div>
      <Space direction='vertical'>
        <small>
          <b>Created At</b> <br /> {utcToLocal(createdAt)}
        </small>
        <small>
          <b>Updated At</b> <br />{utcToLocal(updatedAt)}
        </small>
      </Space>
    </div>
  )
}

export default TimeDetail
