import { Modal, Form, Input, Switch, message, Row, Space, Button, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import shelfAPI from '../../services/shelfAPI'
import userAPI from '../../services/userAPI'

function ShelfFormModal({ visible, onCancel, data, onFinish, rackId }) {
  const [form] = Form.useForm()
  const [staff, setStaff] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    form.resetFields()
    fetchStaff()
    if (data) {
      form.setFieldsValue(data)
    }
  }, [data, visible])

  const submitForm = async (value) => {
    setLoading(true)
    const body = { ...value, rackId: rackId }
    try {
      if (data) {
        await shelfAPI.update(data.id, body)
      }
      else {
        await shelfAPI.create(body)
      }
      message.success('success')
    } catch (error) {
      message.error('something wrong')
    }
    setLoading(false)
    onFinish()
  }

  const fetchStaff = async () => {
    setLoading(true)
    try {
      const res = await userAPI.getAllStaffPaginate({
        page: 1,
        limit: 1000,
      })
      setStaff(res.data.staff.items)
    } catch (error) {
      message.error('error fetch staff')
    }
    setLoading(false)
  }
  return (
    <Modal title={data ? 'Edit Shelf' : 'Create Shelf'} maskClosable={false} visible={visible} footer={false} onCancel={onCancel}>
      <Form
        form={form}
        layout='vertical'
        onFinish={submitForm}
        autoComplete="off"
      >
        <Form.Item
          label="Shelf Code"
          name="code"
          extra="It will be auto generated"
        >
          <Input readOnly />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          extra="It will be auto generated"
        >
          <Input readOnly />
        </Form.Item>

        <Form.Item
          label="Row"
          name="row"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Column"
          name="column"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>


        <Row justify='end'>
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType='submit' loading={loading} type='primary' >Save</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default ShelfFormModal
