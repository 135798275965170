import { Modal, Form, Input, Select, message, Row, Space, Button } from "antd";
import React, { useEffect, useState } from "react";
import userAPI from "../../services/userAPI";
import roleAPI from "../../services/roleAPI";
import { ALL_ACCESS } from "../../utils/accessRight";
import { useSelector } from "react-redux";

function StaffFormModal({ visible, onCancel, data, onFinish }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState([])
  const roleName = useSelector((state) => state.auth.user.role.name);

  useEffect(() => {
    form.resetFields();
    fetchRole()
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data, visible]);

  const submitForm = async (value) => {
    setLoading(true);
    try {
      if (data) {
        await userAPI.updateStaff(data.id, value);
      } else {
        await userAPI.createStaff(value);
      }
      message.success("success");
    } catch (error) {
      message.error("something wrong");
    }
    setLoading(false);
    onFinish();
  };

  const fetchRole = async () => {
    setLoading(true)
    try {
      const res = await roleAPI.getAllRolePaginate({
        page: 1,
        limit: 1000,
      })
      setRole(res.data.data.items)
    } catch (error) {
      message.error('error fetch role')
    }
    setLoading(false)
  }

  return (
    <Modal
      title={data ? "Edit Staff" : "Add Staff"}
      visible={visible}
      footer={false}
      onCancel={onCancel}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={submitForm}
        autoComplete="off"
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item 
          label="Email" 
          name="email" 
          rules={[
            {
              type: 'email',
              message: 'The input is not valid Email!',
            },
            { 
              required: true 
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Role"
          name="roleId"
          rules={[{ required: true }]}
          initialValue={data?.role.id}
        >
         {!data && ALL_ACCESS.includes(roleName) ? 
         <Select >
            {role.map(item =>
              <Select.Option value={item.id}>
                {item.name}
              </Select.Option>
            )}
          </Select> : 
          <Select disabled >
            {role.map(item =>
              <Select.Option value={item.id}>
                {item.name}
              </Select.Option>
            )}
          </Select> }
        </Form.Item>
        <Form.Item 
          label="Phone" 
          name="phone" 
          rules={[
            {
              pattern: '^([-+0-9]*|0)$',
              message: 'The input is not a number',
            },
            { 
              required: true,
              message: 'Phone number required'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              pattern: '^[a-zA-Z0-9~`!@#$%^&*()-_+;:"?,.<>|]{8,}$',
              message: 'At least have 8 characters',
            },
            { 
              required: true,
              message: 'Password required' 
            }
          ]}
        >
          <Input type="password" />
        </Form.Item>

        <Row justify="end">
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType="submit" loading={loading} type="primary">
              Save
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
}

export default StaffFormModal;
