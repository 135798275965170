/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Styles } from '../../styles'

const Spacer = ({ size = 1, border = false }) => {

  return (
    <div css={styles.container(size, border)}>
    </div>
  )
}
const styles = {
  container: (size, border) => css({
    ...Styles['my' + size],
    borderBottom: border && Styles.bottomBorder.borderBottom
  }),
}

export default Spacer
