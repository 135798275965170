import React from 'react'
import { useLocation, Navigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd';
import jwt from 'jsonwebtoken'
import dayjs from 'dayjs'
import config from '../../config';
import { DASHBOARD, WEB_ACCESS } from '../../utils/accessRight';
import { authAction } from '../../redux/reducers/authReducer';

const checkAuth = (auth) => {
  try {
    const decodedToken = jwt.verify(auth.token, config.SECRET_KEY)
    if (dayjs().unix() < decodedToken.exp) {
      return true
    }
  } catch (error) {
    return false
  }
}

const GuestRoute = ({ children, ...rest }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth)
  const roleName = useSelector((state) => state.auth.user?.role.name);
  const isAuthenticated = checkAuth(auth)

  if (isAuthenticated) {
    if (DASHBOARD.includes(roleName)) {
      window.location.replace('/dashboard')
    } else if (WEB_ACCESS.includes(roleName)) {
      window.location.replace('/inbound')
    } else {
      message.error('Access Denied')
      dispatch(authAction.logout());
    }
  }
  return children

}


export default GuestRoute