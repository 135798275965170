import React, { useEffect, useState } from 'react'
import { Line } from '@ant-design/charts';
import dashboardAPI from '../../services/dashboardAPI';
import { utcToLocal } from "../../utils/helper";

function SalesChart({ filters }) {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    let params = {
      ...filters,
    }

    const res = await dashboardAPI.getInboundOutbound(params)

    if (res.outbound.length > res.inbound.length) {
      res.inbound = res.outbound.map((item, index) => ({
        ...item,
        total: res.inbound[index]?.total ?? 0
      }))
    }
    else {
      res.outbound = res.inbound.map((item, index) => ({
        ...item,
        total: res.outbound[index]?.total ?? 0
      }))
    }

    const inboundMapped = res.inbound.map(item => {
      return {
        category: 'Inbound',
        date: utcToLocal(item.createdAt, 'date'),
        value: parseInt(item.total)
      }
    })
    const outboundMapped = res.outbound.map(item => {
      return {
        category: 'Outbound',
        date: utcToLocal(item.createdAt, 'date'),
        value: parseInt(item.total)
      }
    })
    const mapped = [...inboundMapped, ...outboundMapped]

    setData(mapped)
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  const config = {
    data,
    xField: 'date',
    yField: 'value',
    seriesField: 'category',
    yAxis: {
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
  };

  return <Line {...config} />;
}

export default SalesChart
