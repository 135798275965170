import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Row, Space, Button, Typography, Col, Table } from 'antd'
import Spacer from '../layout/Spacer'
import outboundAPI from '../../services/outboundAPI'
import OutboundStatusTag from '../common/OutboundStatusTag';

function OutboundDetailModal({ visible, onCancel, data }) {
  const tableColumns = [
    {
      title: "SKU",
      dataIndex: "product",
      key: "product",
      render: (value) => <span> <b>{value.sku}</b> <br />{value.name} <br /> size: {value.attribute} </span>,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },


    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => <OutboundStatusTag status={value} />
    },
    {
      title: "Available Stock",
      dataIndex: "product",
      key: "product",
      render: (value, record) => {
        const find = value.stocks.find(item => item.warehouseId === outbound?.warehouse.id)
        return find?.availableStock
      }
    },

  ];

  const [form] = Form.useForm()
  const [outboundItems, setOutboundItems] = useState([])
  const [outbound, setOutbound] = useState(null)

  const fetchOutboundDetail = async () => {
    const res = await outboundAPI.getDetail({ id: data.id, admin: true })
    form.setFieldsValue({
      ...res.data.outbound,
      batch: res.data.outbound.batch.id,
      recipientName: res.data.outbound.delivery.recipientName,
      recipientPhone: res.data.outbound.delivery.recipientPhone,
      address: res.data.outbound.delivery.address,
      receiptNumber: res.data.outbound.delivery.receiptNumber,
      externalId: `${res.data.outbound.source} - ${res.data.outbound.externalId}`,
      warehouse: res.data.outbound.warehouse.name
    })

    setOutboundItems(res.data.outbound.items)
    setOutbound(res.data.outbound)
  }

  useEffect(() => {
    form.resetFields()
    if (data) {
      fetchOutboundDetail()
    }
  }, [data, visible])

  return (
    <Modal width={700} title={`Outbound Detail - ${outbound?.code}`} visible={visible} maskClosable={false} footer={false} onCancel={onCancel}>
      <Row>
        <Col md={24}>
          <Form
            form={form}
            layout='vertical'
          >
            <Row gutter={[8, 8]}>
              <Col md={12}>
                <Form.Item
                  label="Batch"
                  name="batch"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Status"
                  name="status"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Warehouse"
                  name="warehouse"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Total Quantity"
                  name="totalQuantity"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="External ID"
                  name="externalId"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Receipt Number"
                  name="receiptNumber"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={24}>
                <Typography.Title level={5}>Delivery</Typography.Title>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Recipient Name"
                  name="recipientName"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Recipient Phone"
                  name="recipientPhone"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item
                  label="Address"
                  name="address"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Spacer />
      <Typography.Title level={5}>Outbound Items</Typography.Title>
      <Table
        columns={tableColumns}
        dataSource={outboundItems}
        pagination={false}
        size="middle"
      />
      <Spacer />
      <Row justify='end'>
        <Space>
          <Button onClick={onCancel}>Close</Button>
        </Space>
      </Row>
    </Modal >
  )
}

export default OutboundDetailModal
