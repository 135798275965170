import React from 'react'
import { Modal, Row, Space, Button, Timeline } from 'antd'
import Spacer from '../layout/Spacer'

function StockOpnameStatusModal({ visible, onCancel, data }) {
  return (
    <Modal title='Stock Opname Status History' visible={visible} maskClosable={false} footer={false} onCancel={onCancel}>
      <Row>
        <Timeline>
          {data?.statusHistory.map((item) => (
            <Timeline.Item>{item}</Timeline.Item>
          ))}
        </Timeline>
      </Row>
      <Spacer />
      <Row justify='end'>
        <Space>
          <Button onClick={onCancel}>Close</Button>
        </Space>
      </Row>
    </Modal >
  )
}

export default StockOpnameStatusModal
