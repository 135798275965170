import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isMounted: false,
  isLoading: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setMounted: (state, action) => {
      state.isMounted = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading, setMounted } = appSlice.actions;

export default appSlice.reducer;
