/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from 'react'
import { Card, Typography, Space, Table, Row, Col, Form, Input } from 'antd'
import { useParams } from 'react-router-dom'
import Spacer from '../../components/layout/Spacer'
import productAPI from '../../services/productAPI'
import { utcToLocal } from "../../utils/helper";
import TimeDetail from '../../components/common/TimeDetail'

function StockHistoryPage() {
  const tableColumns = [
    // {
    //   title: 'Product Name',
    //   dataIndex: 'name',
    //   key: 'name',
    // },
    {
      title: 'Stock Before',
      dataIndex: 'stockBefore',
      key: 'stockBefore',
      render: (value, record) =>
        <div>
          <Space direction='vertical'>
            <span>
              <b>Pending</b> <br />{value.pending}
            </span>
            <span>
              <b>Available</b> <br />{value.available}
            </span>
            <span>
              <b>Locked</b> <br />{value.locked}
            </span>
            <span>
              <b>Shipped</b> <br />{value.shipped}
            </span>
          </Space>
        </div>
    },
    {
      title: 'Stock After',
      dataIndex: 'stockAfter',
      key: 'stockAfter',
      render: (value, record) =>
        <div>
          <Space direction='vertical'>
            <span>
              <b>Pending</b> <br />{value.pending}
            </span>
            <span>
              <b>Available</b> <br />{value.available}
            </span>
            <span>
              <b>Locked</b> <br />{value.locked}
            </span>
            <span>
              <b>Shipped</b> <br />{value.shipped}
            </span>
          </Space>
        </div>
    },
    {
      title: 'Changes',
      dataIndex: 'changes',
      key: 'changes',
      render: (value, record) =>
        <div>
          <Space direction='vertical'>
            <span>
              <b>Pending</b> <br />{value.pending}
            </span>
            <span>
              <b>Available</b> <br />{value.available}
            </span>
            <span>
              <b>Locked</b> <br />{value.locked}
            </span>
            <span>
              <b>Shipped</b> <br />{value.shipped}
            </span>
          </Space>
        </div>
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value, record) => <TimeDetail createdAt={value} updatedAt={record.updatedAt} />
    },
  ];

  const routeParams = useParams()
  const [loading, setloading] = useState(false)
  const [stockHistory, setStockHistory] = useState({
    data: [],
    selected: null,
  })
  
  const [form] = Form.useForm()

  useEffect(() => {
    fetchProduct()
    fetchData()
  }, [])

  const fetchProduct = async () => {
    setloading(true)
    const res = await productAPI.getOne(routeParams.productId)
    form.setFieldsValue({ productName: res.data.product.name, productSku: res.data.product.sku, })
    setloading(false)
  }

  const fetchData = async () => {
    setloading(true)
    const res = await productAPI.getHistory(routeParams.productId)
    setStockHistory({
      data: res.data.product.stocks[0].histories,
      selected: null
    })
    setloading(false)
  }

  return (
    <div>
      <Typography.Title level={2}>Stock History</Typography.Title>
      <Spacer />
      <Card>
        <Row justify='space-between'>
          <Col md={24}>
            <Form layout='inline' form={form}>
              <Form.Item
                name="productName"
                label='Product Name'
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item
                name="productSku"
                label='SKU'
              >
                <Input readOnly />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
      <Spacer />
      <Card>
        <Table
          columns={tableColumns}
          dataSource={stockHistory.data}
          loading={loading}
          scroll={{ x: true }}
        />
      </Card>
    </div>
  )
}

export default StockHistoryPage
