import React, { useEffect, useState } from 'react'
import { Line, Column } from '@ant-design/charts';
import dashboardAPI from '../../services/dashboardAPI';
import { utcToLocal } from "../../utils/helper";

function InboundChart({ filters }) {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    let params = {
      ...filters,
    }

    const res = await dashboardAPI.getQuantityInbound(params)

    const inboundMapped = res.data.inbound.map(item => {
      return {
        date: utcToLocal(item.createdAt, 'date'),
        value: parseInt(item.total)
      }
    })
    setData(inboundMapped)
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  const config = {
    data,
    xField: 'date',
    yField: 'value',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 1,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      date: {
        alias: 'Date',
      },
      value: {
        alias: 'Quantity',
      },
    },
  };

  return <Column {...config} />;
}

export default InboundChart
