/** @jsxImportSource @emotion/react */

import { Button, Col, Card, Form, Input, Row, Typography, message } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import userAPI from '../services/userAPI'
import { authAction } from '../redux/reducers/authReducer'
import { Styles } from '../styles'
import { Link } from 'react-router-dom'
import Spacer from '../components/layout/Spacer'
import images from '../assets/images'
function RegisterPage() {
  const dispatch = useDispatch()

  const [loading, setloading] = useState(false)
  const submitForm = async (value) => {
    try {
      const res = await userAPI.register({ ...value, phone: `+62${value.phone}` })
      const res2 = await userAPI.login({ email: value.email, password: value.password })
      const { user, token } = res2.data
      dispatch(authAction.login({
        user: user,
        token: token,
      }))
    } catch (error) {
      message.error(error.response.data.message)
    }
  }
  return (
    <Row gutter={24} justify='center' >
      <Col xs={24} md={10} >
        <div css={Styles.textCenter}>
          <img src={images.adawmsLogo} width='30%' />
        </div>
        <Spacer />
        <Card>
          <Row justify='space-between' >
            <Typography.Title level={2}>Create Account</Typography.Title>
          </Row>
          <Form
            name="basic"
            layout='vertical'
            onFinish={submitForm}
          >

            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'first name required' }]}
            >
              <Input size='large' />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid Email!',
                },
                {
                  required: true,
                  message: 'Email required'
                }
              ]}
            >
              <Input size='large' />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  pattern: '^[a-zA-Z0-9~`!@#$%^&*()-_+;:"?,.<>|]{8,}$',
                  message: 'At least have 8 characters',
                },
                {
                  required: true,
                  message: 'Password required'
                }
              ]}
            >
              <Input.Password size='large' />
            </Form.Item>

            <Form.Item
              label="Phone number"
              name="phone"
              rules={[
                {
                  pattern: '^([-+0-9]*|0)$',
                  message: 'The input is not a number',
                },
                {
                  required: true,
                  message: 'Phone number required'
                }
              ]}
            >
              <Input size='large' addonBefore={'+62'} />
            </Form.Item>

            <Form.Item>
              <Button block size='large' type="primary" htmlType="submit" loading={loading}>
                Create Account
              </Button>
            </Form.Item>
            <div css={Styles.textCenter}>
              Already have account? <Link to='/login'>Login here</Link>
            </div>

          </Form>
        </Card>
      </Col>
    </Row>

  )
}

export default RegisterPage
