// import dotenv from 'dotenv'
// dotenv.config()

export default {
  API_URL: process.env.REACT_APP_API_URL,
  SECRET_KEY: process.env.REACT_APP_SECRET_KEY,
  REDUX_PERSIST_KEY: process.env.REACT_APP_REDUX_PERSIST_KEY,
  APP_ENV: process.env.REACT_APP_APP_ENV,
  AWS_S3_URL: process.env.REACT_APP_AWS_S3_URL,
  BASE_URL: process.env.REACT_APP_BASE_URL,
}