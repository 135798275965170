import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Row, Space, Button, message, Col } from 'antd'
import { utcToLocal } from '../../utils/helper'
import inboundAPI from '../../services/inboundAPI'
import DatePicker from '../antdcustom/DatePicker'

function InboundApproveModal({ visible, onCancel, data, onFinish }) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const submitForm = async (value, body) => {
    setLoading(true)
    try {
      await inboundAPI.approveBatch(data.id, value)
      message.success('success')
    } catch (error) {
      message.error('something wrong')
    }
    setLoading(false)
    onFinish()
  }

  useEffect(() => {
    form.resetFields()
    if (data) {
      form.setFieldsValue({
        ...data,
        expectedArriveAt: utcToLocal(data.expectedArriveAt),
      })
    }
  }, [data, visible])

  return (
    <Modal title='Approve Inbound' maskClosable={false} visible={visible} footer={false} onCancel={onCancel}>
      <Form
        form={form}
        layout='vertical'
      >
        <Form.Item
          label="Batch ID"
          name="id"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Expected Arrive At"
          name="expectedArriveAt"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Expected Quantity"
          name="totalQuantity"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Received Quantity"
          name="receivedQuantity"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Put Quantity"
          name="putQuantity"
        >
          <Input readOnly />
        </Form.Item>
      </Form>
      <Form
        layout='vertical'
        onFinish={submitForm}
        autoComplete="off"
      >
        <Row gutter={8}>
          <Col md={12}>
            <Form.Item
              label="Courier Name"
              name="courierName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Driver Name"
              name="driverName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="arriveAt"
          label="Arrive At"
          rules={[{ required: true }]}
        >
          <DatePicker />
        </Form.Item>
        <Row justify='end'>
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType='submit' loading={loading} type='primary'>Save</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default InboundApproveModal
