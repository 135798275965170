export default {
  xs: '0.6rem',
  sm: '0.8rem',
  md: '1rem',
  lg: '1.5rem',
  xl: '1.75rem',
  xxl: '2rem',

  fontsm: {
    fontSize: '0.85rem'
  },
  fontmd: {
    fontSize: '1rem'
  },
  fontlg: {
    fontSize: '1.2rem'
  },
  fontxl: {
    fontSize: '1.5rem'
  },
  fontxxl: {
    fontSize: '2rem'
  },
}