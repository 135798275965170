import { combineReducers } from '@reduxjs/toolkit'

import authReducer from './authReducer'
import warehouseReducer from './warehouseReducer'
import appReducer from './appReducer'
import shopReducer from './shopReducer'


const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  warehouse: warehouseReducer,
  shop: shopReducer,
})

export default rootReducer