/** @jsxImportSource @emotion/react */

import { Button, Col, Dropdown, Layout, Menu, Row, Space } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, Outlet } from "react-router-dom";
import logo from "../../assets/images/adawms_logo2.png";
import {
  AppstoreOutlined,
  BarChartOutlined,
  DashboardOutlined,
  DownOutlined,
  HomeOutlined,
  InboxOutlined,
  PaperClipOutlined,
  SendOutlined,
  SettingOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons";
import warehouseAPI from "../../services/warehouseAPI";
import { setLoading, setMounted } from "../../redux/reducers/appReducer";
import { setWarehouses } from "../../redux/reducers/warehouseReducer";
import { authAction } from "../../redux/reducers/authReducer";
import { ALL_ACCESS, DASHBOARD, REPORT, CRUD, IN_OUT } from "../../utils/accessRight";

function AdawmsLayout({ children }) {
  const loc = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const roleName = useSelector((state) => state.auth.user.role.name);
  // const { isLoading } = useSelector((state) => state.app);

  useEffect(async () => {
    // await fetchInitial()
    dispatch(setMounted(true));
  }, []);

  const logout = () => {
    dispatch(authAction.logout());
  };

  const fetchInitial = async () => {
    dispatch(setLoading(true));

    let res = await warehouseAPI.getAllPaginate({
      page: 1,
      limit: 1000,
    });
    dispatch(setWarehouses({ warehouses: res.data.warehouse.items }));

    dispatch(setLoading(false));
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={logout}>Logout</Menu.Item>
    </Menu>
  );
  return (
    <Layout>
      <Layout.Header
        style={{
          position: "fixed",
          zIndex: 1,
          padding: "0 10px ",
          width: "100%",
          background: "white",
        }}
      >
        <Row justify="space-between" align="middle">
          <Col md={3}>
            <img src={logo} alt="" width="100%" />
          </Col>
          <Space>
            <Dropdown overlay={<div></div>}>
              <Button>
                <PaperClipOutlined /> Adawms Bisnis 30 hari <DownOutlined />
              </Button>
            </Dropdown>
            <Dropdown overlay={menu}>
              <Button type="primary">
                <UserOutlined /> {user?.name}
              </Button>
            </Dropdown>
          </Space>
        </Row>
      </Layout.Header>
      <Layout style={{ marginTop: 64 }}>
        <Layout.Sider
          style={{
            position: "fixed",
            height: "100vh",
            overflow: "scroll",
            background: "white",
          }}
        >
          <Menu mode="inline" defaultSelectedKeys={[loc.pathname]}>
            {DASHBOARD.includes(roleName) ?
              <Menu.Item key="/dashboard" icon={<DashboardOutlined />}>
                <NavLink to="/dashboard"> Dashboard</NavLink>
              </Menu.Item>
              : null
            }
            {CRUD.includes(roleName) ?
              <Menu.SubMenu key="product" icon={<TagOutlined />} title="Product">
                <Menu.Item key="/product">
                  <NavLink to="/product">Manage Product</NavLink>
                </Menu.Item>
                <Menu.Item key="/product/category">
                  <NavLink to="/product/category"> Category</NavLink>
                </Menu.Item>
                <Menu.Item key="/product/brand">
                  <NavLink to="/product/brand"> Brand</NavLink>
                </Menu.Item>
              </Menu.SubMenu>
              : null
            }
            {CRUD.includes(roleName) ?
              <Menu.SubMenu key="stock" icon={<AppstoreOutlined />} title="Stock">
                <Menu.Item key="/stock">
                  <NavLink to="/stock"> Manage</NavLink>
                </Menu.Item>
                <Menu.Item key="/stock/opname">
                  <NavLink to="/stock/opname">Opname</NavLink>
                </Menu.Item>
              </Menu.SubMenu>
              : null
            }
            {IN_OUT.includes(roleName) ?
              <>
                <Menu.Item key="/inbound" icon={<InboxOutlined />}>
                  <NavLink to="/inbound"> Inbound</NavLink>
                </Menu.Item>
                <Menu.SubMenu key="outbound" icon={<SendOutlined />} title="Outbound">
                  <Menu.Item key="/outbound">
                    <NavLink to="/outbound">List Outbound</NavLink>
                  </Menu.Item>
                  <Menu.Item key="/outbound/batch">
                    <NavLink to="/outbound/batch">Outbound Batch</NavLink>
                  </Menu.Item>
                </Menu.SubMenu>
              </>
              : null
            }
            {ALL_ACCESS.includes(roleName) ?
              <Menu.Item key="/warehouse" icon={<HomeOutlined />}>
                <NavLink to="/warehouse"> Warehouse</NavLink>
              </Menu.Item>
              : null
            }
            {REPORT.includes(roleName) ?
              <Menu.Item key="/report" icon={<BarChartOutlined />}>
                <NavLink to="/report"> Report</NavLink>
              </Menu.Item>
              : null
            }
            {ALL_ACCESS.includes(roleName) ?
              <Menu.Item key="/setting" icon={<SettingOutlined />}>
                <NavLink to="/setting"> Setting</NavLink>
              </Menu.Item>
              : null
            }
          </Menu>
        </Layout.Sider>
        <Layout.Content
          style={{ padding: 32, marginLeft: 200, minHeight: "100vh" }}
        >
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default AdawmsLayout;
