import { baseAxios } from "./axios"


const create = async (body) => {
  const res = await baseAxios.post('/product-category', body)
  return res.data
}

const getAllPaginate = async (params = {}) => {
  const res = await baseAxios.get('/product-category', { params })
  return res.data
}

const update = async (id, body) => {
  const res = await baseAxios.put('/product-category/' + id, body)
  return res.data
}

const remove = async (id) => {
  const res = await baseAxios.delete('/product-category/' + id)
  return res.data
}
export default { create, update, getAllPaginate, remove }