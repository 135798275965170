import { baseAxios } from "./axios"


const create = async (body) => {
  const res = await baseAxios.post('/rack', body)
  return res.data
}

const getAllPaginate = async (params = {}) => {
  const res = await baseAxios.get('/rack', { params })
  return res.data
}

const update = async (id, body) => {
  const res = await baseAxios.put('/rack/' + id, body)
  return res.data
}

const getShelf = async (id, params = {}) => {
  const res = await baseAxios.get(`/rack/${id}/shelf`, { params })
  return res.data
}

const remove = async (id) => {
  const res = await baseAxios.delete('/rack/' + id)
  return res.data
}

const getOne = async (id) => {
  const res = await baseAxios.get('/rack/' + id)
  return res.data
}
export default { create, update, getAllPaginate, getShelf, remove, getOne }