import Size from "./Size"

const Margin = {
  m1: {
    margin: Size.sm
  },
  m2: {
    margin: Size.md
  },
  m3: {
    margin: Size.lg
  },
  m4: {
    margin: Size.xl
  },
  // mx
  mx1: {
    marginLeft: Size.sm,
    marginRight: Size.sm
  },
  mx2: {
    marginLeft: Size.md,
    marginRight: Size.md
  },
  mx3: {
    marginLeft: Size.lg,
    marginRight: Size.lg
  },
  mx4: {
    marginLeft: Size.xl,
    marginRight: Size.xl
  },
  // my
  my0: {
    marginTop: 0,
    marginBottom: 0
  },
  my1: {
    marginTop: Size.sm,
    marginBottom: Size.sm
  },
  my2: {
    marginTop: Size.md,
    marginBottom: Size.md
  },
  my3: {
    marginTop: Size.lg,
    marginBottom: Size.lg
  },
  my4: {
    marginTop: Size.xl,
    marginBottom: Size.xl
  },
  my5: {
    marginTop: Size.xxl,
    marginBottom: Size.xxl
  },
  // ml
  ml1: {
    marginLeft: Size.sm
  },
  ml2: {
    marginLeft: Size.md
  },
  ml3: {
    marginLeft: Size.lg
  },
  ml4: {
    marginLeft: Size.xl
  },
  // mr
  mr1: {
    margiRight: Size.sm
  },
  mr2: {
    marginRight: Size.md
  },
  mr3: {
    marginRight: Size.lg
  },
  mr4: {
    marginRight: Size.xl
  },

  // mb
  mb1: {
    marginBottom: Size.sm
  },
  mb2: {
    marginBottom: Size.md
  },
  mb3: {
    marginBottom: Size.lg
  },
  mb4: {
    marginBottom: Size.xl
  },
}
export default Margin