import _ from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export function currencyFormat(number) {
  if (isNaN(number)) {
    return 'Rp 0'
  }

  let countryCode = 'ID'
  let currency = 'IDR'

  const result = new Intl.NumberFormat(countryCode, {
    style: 'currency', currency: currency, minimumFractionDigits: 0
  }).format(number)

  return result
}

export function utcToLocal(utcTime, format = null) {
  const utc = dayjs.utc(utcTime)

  if (format == 'fromNow') {
    return utc.local().fromNow()
  }
  else if (format == 'long') {
    return utc.local().format('DD MMM YYYY, HH:mm')
  }
  else if (format == 'time') {
    return utc.local().format('HH:mm')
  }
  else if (format == 'date') {
    return utc.local().format('DD/MM/YY')
  }
  else {
    return utc.local().format('DD MMM YYYY, HH:mm')
  }

}

export function upperWords(words) {
  return _.startCase(_.camelCase(words))
}

export function sumTotal(payload, key) {
  return payload.reduce((prev, curr) => prev + parseInt(curr[key]), 0)
}

export function limitNumber(a, limit = 0) {
  return a < limit ? limit : a
}