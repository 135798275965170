export const COLOR_LIST = [
  'None',
  'White',
  'Black',
  'Green',
  'Blue',
  'Red',
  'Yellow',
  'Violet',
  'Orange'
]

export const INITIAL_PAGINATION = ({
  current: 1,
  pageSize: 10,
  total: 0,
})

export const OUTBOUND_STATUS = {
  PENDING: 'pending',
  READY: 'ready', // need to approved
  AWAIT_PACKING: 'await packing',
  AWAIT_SHIPMENT: 'await shipment',
  SHIPPED: 'shipped',
  CANCELED: 'canceled',
  UNDERSTOCK: 'understock',
};