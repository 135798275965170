import { baseAxios } from "./axios"

const getReportToday = async (params = {}) => {
  const res = await baseAxios.get('/report/report-today', { params })
  return res.data
}

const getReportStatus = async (params = {}) => {
  const res = await baseAxios.get('/outbound/report/status-order', { params })
  return res.data
}

const exportReportStatus = async (params = {}) => {
  const res = await baseAxios.get('/outbound/report/export-excel', { params })
  return res.data
}

export default { getReportToday, getReportStatus, exportReportStatus }