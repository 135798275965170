import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Modal,
  Form,
  Input,
  Switch,
  message,
  Row,
  Space,
  Button,
  Select,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import productCategoryAPI from "../../services/productCategoryAPI";
import Spacer from "../layout/Spacer";

function ProductCategoryFormModal({
  visible,
  onCancel,
  data,
  onFinish,
  warehouseId,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    form.resetFields();
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data, visible]);

  const submitForm = async (value) => {
    setLoading(true);
    const body = { ...value, warehouseId: warehouseId };
    try {
      if (data) {
        await productCategoryAPI.update(data.id, body);
      } else {
        await productCategoryAPI.create(body);
      }
      message.success("success");
    } catch (error) {
      message.error("something wrong");
    }
    setLoading(false);
    onFinish();
  };

  return (
    <Modal
      title={data ? "Edit Product Category" : "Create Product Category"}
      visible={visible}
      footer={false}
      onCancel={onCancel}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={submitForm}
        autoComplete="off"
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Typography.Title level={5}>Attributes</Typography.Title>
        <Spacer border />
        <Form.List name="attributes">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => {
                return (
                  <Space key={field.key} style={{ display: "flex" }}>
                    <Form.Item
                      {...field}
                      label={"Attribute Name"}
                      name={[field.name, "name"]}
                      fieldKey={[field.fieldKey, "name"]}
                      rules={[
                        { required: true, message: "attribute name required" },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                );
              })}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Variations
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Row justify="end">
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType="submit" loading={loading} type="primary">
              Save
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
}

export default ProductCategoryFormModal;
