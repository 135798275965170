/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Select,
  Row,
  Typography,
  Button,
  Space,
  Table,
  DatePicker,
  Col,
  message,
  Modal,
  Input,
  Tag
} from "antd";
import Spacer from "../../components/layout/Spacer";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import productBrandAPI from "../../services/productBrandAPI";
import OutboundAPI from "../../services/outboundAPI"
import dayjs from 'dayjs';
import TimeDetail from "../../components/common/TimeDetail";
import OutboundDetailModal from "../../components/outbound/OutboundDetailModal";
import OutboundStatusModal from "../../components/outbound/OutboundStatusModal";
import { useSelector } from "react-redux";
import { CRUD } from "../../utils/accessRight";
import { INITIAL_PAGINATION } from "../../utils/constant";
import warehouseAPI from "../../services/warehouseAPI";
import OutboundStatusTag from "../../components/common/OutboundStatusTag";
import OutboundToShippedModal from "../../components/outbound/OutboundToShippedModal";

function OutboundPage() {
  const roleName = useSelector((state) => state.auth.user.role.name);

  const tableColumns = [
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
      render: (value, record) => <span>#{value.id}</span>
    },
    {
      title: "Order ID",
      dataIndex: "externalId",
      key: "externalId",
    },
    {
      title: "Products",
      dataIndex: "items",
      key: "items",
      render: (items) =>
        <ul style={{
          overflowY: 'scroll', paddingInlineStart: "20px", maxHeight: '100px'
        }}>
          {items.map((item) => (
            <li key={item.id}><b>{item.product.sku}</b> - {item.product.name} ({item.quantity}x)</li>
          ))}
        </ul>
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => <OutboundStatusTag status={value} />

    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value, record) => <TimeDetail createdAt={value} updatedAt={record.updatedAt} />
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (value, record) => (
        <Space direction="vertical">
          <Button type="link" size="small" onClick={() => selectDetail(record)}>
            Detail
          </Button>
          {/* {CRUD.includes(roleName) ?
            <Button type="link" size="small" onClick={() => selectData(record)}>
              Edit
            </Button>
            : null
          } */}
          <Button type="link" size="small" onClick={() => selectStatus(record)}>
            Status History
          </Button>
          {
            ["pending", 'understock', 'ready'].includes(record.status) &&
            <>
              <Button type="link" size="small" onClick={() => cancelConfirm(value)}>
                Cancel
              </Button>
            </>
          }
        </Space>
      ),
    },
  ];

  const { RangePicker } = DatePicker;
  const [form] = Form.useForm()

  const [loading, setloading] = useState(false);
  const [updateShippedModal, setUpdateShippedModal] = useState(false)
  const [productBrand, setProductBrand] = useState({
    data: [],
    selected: null,
  });

  const [warehouse, setWarehouse] = useState({
    data: [],
    selected: null
  })

  const [outbound, setOutbound] = useState({
    data: [],
    selected: null,
  });

  const [pagination, setPagination] = useState(INITIAL_PAGINATION);

  // const [selectedItem, setSelectedItem] = useState([])

  const [detailModal, setDetailModal] = useState(false)

  const [statusModal, setStatusModal] = useState(false)

  const [filters, setFilters] = useState({
    startDate: dayjs().add(-1, 'day').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  });

  const selectData = (record) => {
    setProductBrand({ ...productBrand, selected: record });
  };

  const selectDetail = (record) => {
    setOutbound({ ...outbound, selected: record });
    setDetailModal(true)
  };

  const selectStatus = (record) => {
    setOutbound({ ...outbound, selected: record });
    setStatusModal(true)
  };

  // const approveConfirm = (id) => {
  //   Modal.confirm({
  //     title: "Do you Want to approve this item?",
  //     icon: <ExclamationCircleOutlined />,
  //     content: "This action cannot be undone",
  //     onOk: async () => {
  //       await OutboundAPI.approve([id]);
  //       message.success("Approve item success");
  //       fetchData(pagination, filters);
  //     },
  //   });
  // };

  // const approveAllConfirm = (selectedItem) => {
  //   Modal.confirm({
  //     title: "Do you Want to approve this items?",
  //     icon: <ExclamationCircleOutlined />,
  //     content: "This action cannot be undone",
  //     onOk: async () => {
  //       await OutboundAPI.approve(selectedItem);
  //       message.success("Approve items success");
  //       fetchData(pagination, filters);
  //     },
  //   });
  // };

  const cancelConfirm = (id) => {
    Modal.confirm({
      title: "Do you want to cancel this outbound?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone",
      onOk: async () => {
        await OutboundAPI.cancel(id);
        message.success("Cancel outbound success");
        fetchData(pagination, filters);
      },
    });
  };

  const fetchData = async (pagination, filters) => {
    setloading(true);
    let params = {
      ...filters,
      page: pagination.current,
      limit: pagination.pageSize,
      includes: ['items'],
    };

    const res = await OutboundAPI.getAllPaginate(params);
    setOutbound({
      data: res.data.outbounds.items,
      selected: null,
    });
    setPagination({
      current: res.data.outbounds.meta.currentPage,
      pageSize: res.data.outbounds.meta.itemsPerPage,
      total: res.data.outbounds.meta.totalItems,
    });
    setloading(false);
  };

  const fetchBrand = async () => {
    const params = {
      page: 1,
      limit: 1000,
    }
    const res = await productBrandAPI.getAllPaginate(params)
    const sortBrand = res.data.productBrand.items.sort((a, b) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      }
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }
      return 0;
    });
    setProductBrand({
      data: sortBrand,
      selected: null,
    })
  }

  const fetchWarehouse = async () => {
    const params = {
      page: 1,
      limit: 1000,
    }
    const res = await warehouseAPI.getAllPaginate(params)
    setWarehouse({
      data: res.data.warehouse.items,
      selected: null,
    })
  }

  const submitFilter = (value) => {
    const data = {
      ...value,
      startDate: value.date[0].format('YYYY-MM-DD'),
      endDate: value.date[1].format('YYYY-MM-DD'),
    }
    setFilters(data)
  };

  const exportOutbound = async (filters) => {
    setloading(true)
    let params = {
      ...filters,
    }
    const res = await OutboundAPI.exports(params)
    window.location.href = res.data.outbound.Location
    setloading(false)
  }

  useEffect(() => {
    fetchData(INITIAL_PAGINATION, filters);
    setPagination(INITIAL_PAGINATION)
  }, [filters]);

  useEffect(() => {
    fetchBrand();
    fetchWarehouse();
    form.resetFields()
  }, []);

  return (
    <div>
      <Typography.Title level={2}>Outbound</Typography.Title>
      <Card
        title="List of Outbound"
        extra={
          <Space>
            {/* <Button type="primary"
              disabled={selectedItem.length > 0 ? false : true}
              onClick={() => approveAllConfirm(selectedItem)}
            >
              Approve All
            </Button> */}
            <Button type="primary"
              loading={loading}
              onClick={() => setUpdateShippedModal(true)}
            > Ship Outbound </Button>
            <Button type="primary"
              loading={loading}
              onClick={() => exportOutbound(filters)}
            > Export </Button>
          </Space>
        }
      >
        <Row justify="space-between">
          <Col md={24}>
            <Form
              onFinish={submitFilter}
              layout="inline"
              initialValues={{ date: [dayjs().add(-1, 'day'), dayjs()] }}
            >
              <Form.Item name="brandId" label="Brand">
                <Select placeholder="Select Brand">
                  {productBrand.data.map((item) => (
                    <Select.Option value={item.id}>{item.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="date" label="Outbound Date">
                <RangePicker />
              </Form.Item>
              <Form.Item name="status" label="Status">
                <Select placeholder="Select Status">
                  <Select.Option value="">All Status</Select.Option>
                  <Select.Option value="understock">Understock</Select.Option>
                  <Select.Option value="pending">Pending</Select.Option>
                  <Select.Option value="ready">Ready to Outbound</Select.Option>
                  <Select.Option value="await packing">Await Packing</Select.Option>
                  <Select.Option value="await shipment">Await Shipment</Select.Option>
                  <Select.Option value="shipped">Shipped</Select.Option>
                  <Select.Option value="canceled">Cancelled</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="warehouseId" label="Warehouse">
                <Select placeholder="Select Warehouse">
                  {warehouse.data.map((item) => (
                    <Select.Option value={item.id}>{item.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="externalId" label="Search">
                <Input placeholder="Order ID..." suffix={<SearchOutlined />} />
              </Form.Item>
              <Button htmlType="submit" type="primary">
                Search
              </Button>
            </Form>
          </Col>
        </Row>
      </Card>
      <Spacer />
      <Card>
        <Table
          columns={tableColumns}
          pagination={pagination}
          onChange={(pagination, _filter, _sorter) => fetchData(pagination, filters)}
          dataSource={outbound.data}
          loading={loading}
          scroll={{ x: true }}
        // rowKey={(record) => record.id}
        // rowSelection={{
        //   type: 'checkbox',
        //   selectedRowKeys: selectedItem,
        //   onChange: (key, record) => {
        //     setSelectedItem(key)
        //   },
        //   getCheckboxProps: (record) => ({
        //     disabled: record.status !== 'pending'
        //   })
        // }}
        />
      </Card>

      <OutboundDetailModal
        visible={detailModal}
        data={outbound.selected}
        onCancel={() => {
          setDetailModal(false)
        }}
      />

      <OutboundStatusModal
        visible={statusModal}
        data={outbound.selected}
        onCancel={() => {
          setStatusModal(false)
        }}
      />

      <OutboundToShippedModal
        visible={updateShippedModal}
        onCancel={() => {
          setUpdateShippedModal(false)
        }}
      />
    </div>
  );
}

export default OutboundPage;
