import { baseAxios } from "./axios"

const create = async (body) => {
  const res = await baseAxios.post('/stock', body)
  return res.data
}

const getAllPaginate = async (params = {}) => {
  const res = await baseAxios.get('/stock', { params })
  return res.data
}

const update = async (id, body) => {
  const res = await baseAxios.put('/stock/' + id, body)
  return res.data
}

const getOne = async (id, params = {}) => {
  const res = await baseAxios.get('/stock/' + id, { params })
  return res.data
}

const getAllHistoryPaginate = async (id, params = {}) => {
  const res = await baseAxios.get(`/stock/${id}/history`, { params })
  return res.data
}

const exportStockReport = async (params = {}) => {
  const res = await baseAxios.get('/stock/export-excel', { params })
  return res.data
}

const stockOpname = async (params = {}) => {
  const res = await baseAxios.get('/stock-opname', { params })
  return res.data
}

const updateStockOpname = async (id, body) => {
  const res = await baseAxios.put('/stock-opname/'+ id, body)
  return res.data
}
const updateStockOpnameStatus = async (id, body) => {
  const res = await baseAxios.put(`/stock-opname/${id}/status`, body)
  return res.data
}

export default { create, update, getAllPaginate, getOne, getAllHistoryPaginate, exportStockReport, stockOpname, updateStockOpname,updateStockOpnameStatus }