import { Modal, Form, Input, Switch, message, Row, Space, Button, Select, Typography, Col, notification, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import productAPI from '../../services/productAPI'
import productBrandAPI from '../../services/productBrandAPI'
import { useSelector } from 'react-redux'
import config from '../../utils/config'
import { UploadOutlined } from '@ant-design/icons'

function ProductImportModal({ visible, onCancel, data, onFinish }) {
  const auth = useSelector(state => state.auth)

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const [brand, setBrand] = useState([])
  useEffect(() => {
    form.resetFields()
    fetchBrand()
  }, [visible])

  const submitForm = async (value) => {
    setLoading(true)
    try {
      const res = await productAPI.createImport({
        ...value,
        file: {
          name: value.files[0].response.data.Key,
          url: value.files[0].response.data.Location
        }
      })

      notification.info({
        duration: null,
        message: `Import Done`,
        description: (<div>
          <b>Success</b>
          <p>
            {res.data.product.success?.affectedRows || 0} data created
          </p>
          <b>Failed</b>
          <p>
            {res.data.product.failed.length} data failed
          </p>
          <ol style={{ overflowY: 'scroll', maxHeight: '20vh' }}>
            {res.data.product.failed.map(item => <li>{item}</li>)}
          </ol>
        </div>),
        placement: 'top',
      });
      setLoading(false)
    } catch (error) {
      notification.error({
        message: `Import Failed`,
        placement: 'top',
        duration: 10,
        description: error.response.data.message,
      });
      setLoading(false)

    }
    setLoading(false)
    onFinish()
  }

  const fetchBrand = async () => {
    setLoading(true)
    try {
      const res = await productBrandAPI.getAllPaginate({
        page: 1,
        limit: 1000,
      })
      setBrand(res.data.productBrand.items)
    } catch (error) {
      message.error('Error fetch brand')
    }
    setLoading(false)
  }
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const onChangeUpload = ({ fileList, file }) => {
    if (file.status == 'uploading') {
      setLoading(true)
    }
    else if (file.status == 'done') {
      setLoading(false)
    }
  }

  return (
    <Modal title={'Import Product'} maskClosable={false} visible={visible} footer={false} onCancel={onCancel}>
      <Form
        form={form}
        layout='vertical'
        onFinish={submitForm}
        autoComplete="off"
      >

        <Form.Item
          label="Brand"
          name="brandId"
          rules={[{ required: true }]}
          initialValue={data?.brand.id}
        >
          <Select>
            {brand.map(item =>
              <Select.Option value={item.id}>
                {item.name}
              </Select.Option>
            )}
          </Select>
        </Form.Item>


        <Form.Item
          name='files'
          label='File'
          getValueFromEvent={normFile}
          valuePropName="fileList"
          rules={[{ required: true }]}
        >
          <Upload
            maxCount={1}
            action={config.API_URL + '/file/upload?folder=product'}
            headers={{ Authorization: 'Bearer ' + auth.token }}
            type='drag'
            onChange={onChangeUpload}>
            <UploadOutlined /> Click or drag files to this area to upload
          </Upload>
        </Form.Item>

        <Button href={`${config.AWS_S3_URL}/template/adawms-product-template.xlsx`}>
          Download template file
        </Button>

        <Row justify='end'>
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType='submit' loading={loading} type='primary' >Save</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default ProductImportModal
