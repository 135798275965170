import { Modal, Form, Input, Switch, message, Row, Space, Button, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import warehouseAPI from '../../services/warehouseAPI'
import userAPI from '../../services/userAPI'

function WarehouseFormModal({ visible, onCancel, data, onFinish }) {
  const [form] = Form.useForm()
  const [staff, setStaff] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    form.resetFields()
    fetchStaff()
    if (data) {
      form.setFieldsValue(data)
    }
  }, [data, visible])

  const submitForm = async (value) => {
    setLoading(true)
    try {
      if (data) {
        await warehouseAPI.update(data.id, value)
      }
      else {
        await warehouseAPI.create(value)
      }
      message.success('success')
    } catch (error) {
      message.error('something wrong')
    }
    setLoading(false)
    onFinish()
  }


  const fetchStaff = async () => {
    setLoading(true)
    try {
      const res = await userAPI.getAllStaffPaginate({
        page: 1,
        limit: 1000,
      })
      setStaff(res.data.staff.items)
    } catch (error) {
      message.error('error fetch staff')
    }
    setLoading(false)
  }

  return (
    <Modal title={data ? 'Edit Warehouse' : 'Create Warehouse'} maskClosable={false} visible={visible} footer={false} onCancel={onCancel}>
      <Form
        form={form}
        layout='vertical'
        onFinish={submitForm}
        autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Address"
          name="address"
          rules={[{ required: true }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="Warehouse Code"
          name="code"
          extra="It will be auto generated"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Warehouse Head"
          name="warehouseHead"
          rules={[{ required: true }]}
          initialValue={data?.warehouseHead}

        >
          <Select>
            {staff.map(item =>
              <Select.Option value={item.name}>
                {item.name}
              </Select.Option>
            )}
          </Select>
        </Form.Item>




        <Row justify='end'>
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType='submit' loading={loading} type='primary' >Save</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default WarehouseFormModal
