import { PlusOutlined } from '@ant-design/icons'
import { Modal, Form, Input, message, Row, Space, Button, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import productBrandAPI from '../../services/productBrandAPI'
import config from '../../utils/config'

function ProductBrandFormModal({ visible, onCancel, data, onFinish }) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const auth = useSelector(state => state.auth)

  useEffect(() => {
    form.resetFields()
    if (data) {
      form.setFieldsValue(data)
    }
  }, [data, visible])

  const submitForm = async (value) => {
    setLoading(true)
    const file = {
      name: value.files[0].response.data.Key,
      url: value.files[0].response.data.Location
    }
    const body = { ...value, file }
    try {
      if (data) {
        await productBrandAPI.update(data.id, body)
      }
      else {
        await productBrandAPI.create(body)
      }
      message.success('success')
    } catch (error) {
      message.error('something wrong')
    }
    setLoading(false)
    onFinish()
  }

  const fileValidation = (file) => {
    const isPNG = file.type === 'image/png';
    const isJPG = file.type === 'image/jpg';
    const isJPEG = file.type === 'image/jpeg';
    if (!isPNG && !isJPEG && !isJPG) {
      message.error(`${file.name} is not an image file`);
    } 
    return isPNG || isJPEG || isJPG || Upload.LIST_IGNORE;
  }

  const onChangeUpload = ({ fileList, file }) => {
    if (file.status == 'uploading') {
      setLoading(true)
    }
    else if (file.status == 'done') {
      setLoading(false)
    }
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Modal title={data ? 'Edit Product Brand' : 'Create Product Brand'} visible={visible} maskClosable={false} footer={false} onCancel={onCancel}>
      <Form
        form={form}
        layout='vertical'
        onFinish={submitForm}
        autoComplete="off"
      >

        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='files'
          label='Brand Image'
          rules={[{ required: true }]}
          getValueFromEvent={normFile}
          valuePropName="fileList"
        >
          <Upload
            beforeUpload={fileValidation}
            maxCount={1}
            action={config.API_URL + '/file/upload?folder=brand'}
            headers={{ Authorization: 'Bearer ' + auth.token }}
            listType='picture-card'
            accept='.png, .jpg, .jpeg'
            onChange={onChangeUpload}
            >
            {data?.imageUrl ? 
            <img src={data?.imageUrl} alt="avatar" style={{ width: '100%' }} 
            /> 
            : 
            <Space direction='vertical'>
              <span><PlusOutlined/></span>
              <span>Upload</span>
            </Space>}
          </Upload>
        </Form.Item>

        <Row justify='end'>
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType='submit' loading={loading} type='primary' >Save</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default ProductBrandFormModal
