

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: null,
  token: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user
      state.token = action.payload.token
    },
    logout: (state, action) => {
      state.user = null
      state.token = null
    },
  },
})

const { login, logout } = authSlice.actions

export const authAction = { login, logout }
export default authSlice.reducer