/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Select,
  Row,
  Typography,
  Button,
  Space,
  Table,
  Modal,
  message,
  DatePicker,
  Col,
  Tag,
  Input,
  Menu,
  Dropdown,
  notification,
  Checkbox,
} from "antd";
import Spacer from "../../components/layout/Spacer";
import { ExclamationCircleOutlined, SearchOutlined, DownOutlined, HomeOutlined } from "@ant-design/icons";
import productBrandAPI from "../../services/productBrandAPI";
import OutboundAPI from "../../services/outboundAPI"
import dayjs from 'dayjs';
import outboundAPI from "../../services/outboundAPI";
import TimeDetail from "../../components/common/TimeDetail";
import OutboundBatchDetailModal from "../../components/outbound/OutboundBatchDetailModal";
import { useSelector } from "react-redux";
import { CRUD } from "../../utils/accessRight";
import { INITIAL_PAGINATION, OUTBOUND_STATUS } from "../../utils/constant";
import config from "../../utils/config";
import OutboundFormModal from "../../components/outbound/OutboundFormModal";
import OutboundStatusTag from "../../components/common/OutboundStatusTag";
import OutboundPrintModal from "../../components/outbound/OutboundPrintModal";
import OutboundBatchStatusTag from "../../components/common/OutboundBatchStatusTag";
import { ChannelIcon } from "../../components/common/ChannelIcon";
import { Size } from "../../styles";

function OutboundBatchPage() {
  const roleName = useSelector((state) => state.auth.user.role.name);

  const tableColumns = [
    {
      title: "Batch",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Order ID",
      dataIndex: "outbounds",
      key: "outbounds",
      render: (items) =>
        <ul style={{
          overflowY: 'scroll', paddingInlineStart: "20px", maxHeight: '100px'
        }}>
          {items.map((item) => (<li key={item.id}>
            <Typography.Text
              ellipsis={{ tooltip: item.externalId }}
              strong
              style={{ width: 150, fontSize: Size.sm }}>
              {item.externalId}
            </Typography.Text>

          </li>))}
        </ul>
    },
    {
      title: "Brand/Warehouse",
      dataIndex: "outbounds",
      key: "outbounds",
      render: (value) => <Space direction="vertical">

        <span><ChannelIcon channelName={value[0].source} />  {value[0].items[0].product?.brand?.name}</span>
        <span>  {value[0].warehouse.name}</span>
      </Space>
    },
    {
      title: "Item Status",
      dataIndex: "status",
      key: "status",
      render: (value, record) => {
        const understock = record.outbounds.filter(i => OUTBOUND_STATUS.UNDERSTOCK == i.status).length
        const pending = record.outbounds.filter(i => OUTBOUND_STATUS.PENDING == i.status).length
        const ready = record.outbounds.filter(i => OUTBOUND_STATUS.READY == i.status).length
        const canceled = record.outbounds.filter(i => OUTBOUND_STATUS.CANCELED == i.status).length
        const awaitpacking = record.outbounds.filter(i => OUTBOUND_STATUS.AWAIT_PACKING == i.status).length
        const awaitshipment = record.outbounds.filter(i => OUTBOUND_STATUS.AWAIT_SHIPMENT == i.status).length
        const shipped = record.outbounds.filter(i => OUTBOUND_STATUS.SHIPPED == i.status).length
        // return record.outbounds.some(i => ['understock'].includes(i.status)) ?
        return <Space direction="vertical">
          {understock > 0 && <OutboundStatusTag status={OUTBOUND_STATUS.UNDERSTOCK} count={understock} />}
          {pending > 0 && <OutboundStatusTag status={OUTBOUND_STATUS.PENDING} count={pending} />}
          {ready > 0 && <OutboundStatusTag status={OUTBOUND_STATUS.READY} count={ready} />}
          {canceled > 0 && <OutboundStatusTag status={OUTBOUND_STATUS.CANCELED} count={canceled} />}
          {awaitshipment > 0 && <OutboundStatusTag status={OUTBOUND_STATUS.AWAIT_SHIPMENT} count={awaitshipment} />}
          {awaitpacking > 0 && <OutboundStatusTag status={OUTBOUND_STATUS.AWAIT_PACKING} count={awaitpacking} />}
          {shipped > 0 && <OutboundStatusTag status={OUTBOUND_STATUS.SHIPPED} count={shipped} />}
        </Space>
        // :
        // <OutboundStatusTag status={value} />
      }
    },
    {
      title: "Batch Status",
      dataIndex: "isPrinted",
      key: "isPrinted",
      render: (value, record) =>
        <Space direction="vertical">
          <OutboundBatchStatusTag status={record.status} outbounds={record.outbounds} />

          {value ? <Tag color='green'>Printed</Tag> : <Tag color='red'>Not Printed</Tag>}
        </Space>

    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value, record) => <TimeDetail createdAt={value} updatedAt={record.updatedAt} />
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (value, record) => (
        <Space direction="vertical">
          <Button type="link" size="small" onClick={() => selectDetail(record)}>
            Detail
          </Button>
          <Button type="link" size="small" onClick={() => selectPrint(record)}>
            Print label
          </Button>
          {
            record.outbounds.some(i => ['pending', 'understock'].includes(i.status)) && CRUD.includes(roleName) ?
              <>
                <Button type="link" size="small" onClick={() => cancelConfirm(value)}>
                  Cancel
                </Button>
                <Button type="link" size="small" onClick={() => approveConfirm(value)}>
                  Approve
                </Button>
              </>
              : null
          }
        </Space>
      ),
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setCreateModal(true)}>
        Import File
      </Menu.Item>
      <a href={`${config.AWS_S3_URL}/template/adawms-outbound-template.xlsx`}>
        <Menu.Item key="2">
          Download template file
        </Menu.Item>
      </a>
    </Menu>
  );

  const { RangePicker } = DatePicker;
  const [form] = Form.useForm()

  const [loading, setloading] = useState(false);
  const [createModal, setCreateModal] = useState(false)
  const [printModal, setPrintModal] = useState(false)
  const [productBrand, setProductBrand] = useState({
    data: [],
    selected: null,
  });

  const [outbound, setOutbound] = useState({
    data: [],
    selected: null,
  });

  const [pagination, setPagination] = useState(INITIAL_PAGINATION);

  const [selectedItem, setSelectedItem] = useState([])

  const [filters, setFilters] = useState({
    startDate: dayjs().add(-1, 'day').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  });

  const [detailModal, setDetailModal] = useState(false)

  const fetchData = async (pagination, filters) => {
    setloading(true);
    const params = {
      ...filters,
      page: pagination.current,
      limit: pagination.pageSize,
    };

    const res = await OutboundAPI.getAllBatchPaginate(params);
    setOutbound({
      data: res.data.outbounds.items,
      selected: null,
    });
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: res.data.outbounds.meta.totalItems,
    });
    setloading(false);
  };

  const fetchBrand = async () => {
    const params = {
      page: 1,
      limit: 1000,
    }
    const res = await productBrandAPI.getAllPaginate(params)
    const sortBrand = res.data.productBrand.items.sort((a, b) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      }
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }
      return 0;
    });
    setProductBrand({
      data: sortBrand,
      selected: null,
    })
  }

  const submitFilter = (value) => {
    const data = {
      ...value,
      startDate: value.date[0].format('YYYY-MM-DD'),
      endDate: value.date[1].format('YYYY-MM-DD'),
    }
    setFilters(data)
  };

  const selectDetail = (record) => {
    setOutbound({ ...outbound, selected: record });
    setDetailModal(true)
  };

  const selectPrint = (record) => {
    setOutbound({ ...outbound, selected: record });
    setPrintModal(true)
  };


  const approveConfirm = (id) => {
    Modal.confirm({
      title: "Do you want to approve this outbound batch?",
      icon: <ExclamationCircleOutlined />,
      content: "Undertock outbounds will be updated if available stock is sufficient",
      onOk: async () => {
        const res = await OutboundAPI.approveBatch([id]);
        notification.info({
          duration: null,
          message: 'Approve success',
          placement: 'top',
          description: (
            <>
              <b>Info:</b>
              <p>{res.data.ready} ready to outbound</p>
            </>
          )
        })
        fetchData(pagination, filters);
      },
    });
  };

  const approveAllConfirm = (selectedItem) => {
    Modal.confirm({
      title: "Do you want to approve all these outbound batches?",
      icon: <ExclamationCircleOutlined />,
      content: "Undertock outbounds will be updated if available stock is sufficient",
      onOk: async () => {
        await OutboundAPI.approveBatch(selectedItem);
        message.success("Approve items success");
        fetchData(pagination, filters);
      },
    });
  };

  const cancelConfirm = (id) => {
    Modal.confirm({
      title: "Do you want to cancel this outbound batch?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone",
      onOk: async () => {
        await OutboundAPI.cancelBatch(id);
        message.success("Cancel outbound batch success");
        fetchData(pagination, filters);
      },
    });
  };

  useEffect(() => {
    fetchData(INITIAL_PAGINATION, filters);
    setPagination(INITIAL_PAGINATION)
  }, [filters]);

  useEffect(() => {
    fetchBrand();
    form.resetFields()
  }, []);

  return (
    <div>
      <Typography.Title level={2}>Outbound Batch</Typography.Title>
      <Card
        title="List of Outbound Batch"
        extra={
          <Space>
            {CRUD.includes(roleName) ?
              <>
                <Button type="primary"
                  disabled={true}
                  onClick={() => approveAllConfirm(selectedItem)}
                >
                  Approve All
                </Button>
                <Dropdown overlay={menu}>
                  <Button type="primary">
                    New Outbound <DownOutlined />
                  </Button>
                </Dropdown>
              </>
              : null
            }
          </Space>
        }
      >
        <Row justify="space-between">
          <Col md={24}>
            <Form
              onFinish={submitFilter}
              layout="inline"
              initialValues={{ date: [dayjs().add(-1, 'day'), dayjs()] }}
            >
              <Form.Item name="brandId" label="Brand">
                <Select placeholder="Select Brand">
                  {productBrand.data.map((item) => (
                    <Select.Option value={item.id}>{item.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="date" label="Due Date">
                <RangePicker />
              </Form.Item>
              <Form.Item name="status" label="Status">
                <Select placeholder="Select Status">
                  <Select.Option value="">All Status</Select.Option>
                  <Select.Option value="pending">Pending</Select.Option>
                  <Select.Option value="ready">Ready to Outbound</Select.Option>
                  <Select.Option value="approved">Approved</Select.Option>

                  <Select.Option value="canceled">Cancelled</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="isPrinted" label="Printed Status">
                <Select placeholder="Select Status">
                  <Select.Option value="">All Status</Select.Option>
                  <Select.Option value="1">Printed</Select.Option>
                  <Select.Option value="0">Not Printed</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="externalId" label="Search">
                <Input placeholder="Order ID..." suffix={<SearchOutlined />} />
              </Form.Item>
              <Button htmlType="submit" type="primary">
                Search
              </Button>
            </Form>
          </Col>
        </Row>
      </Card>
      <Spacer />
      <Card>
        <Table
          columns={tableColumns}
          pagination={pagination}
          onChange={(pagination, _filter, _sorter) => fetchData(pagination, filters)}
          dataSource={outbound.data}
          loading={loading}
          scroll={{ x: true }}
          rowKey={(record) => record.id}
          rowSelection={{
            type: 'checkbox',
            preserveSelectedRowKeys: true,
            selectedRowKeys: selectedItem,
            onChange: (key, record) => {
              setSelectedItem(key)
            },
            getCheckboxProps: (record) => ({
              disabled: record.status !== 'pending'
            })
          }}
        />
      </Card>
      <OutboundBatchDetailModal
        visible={detailModal}
        data={outbound.selected}
        onCancel={() => {
          setDetailModal(false)
        }}
      />
      <OutboundFormModal
        onFinish={() => {
          fetchData(pagination, filters)
          setCreateModal(false)
        }}
        brand={productBrand.data}
        onCancel={() => setCreateModal(false)}
        visible={createModal}
      />

      <OutboundPrintModal visible={printModal}
        data={outbound.selected}
        onFinish={() => {
          setPrintModal(false)
          fetchData(pagination)
        }} onCancel={() => setPrintModal(false)} footer={null} />
    </div>
  );
}

export default OutboundBatchPage;
