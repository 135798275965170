import { baseAxios } from "./axios"


const create = async (body) => {
  const res = await baseAxios.post('/shelf', body)
  return res.data
}

const getAllPaginate = async (params = {}) => {
  const res = await baseAxios.get('/shelf', { params })
  return res.data
}

const update = async (id, body) => {
  const res = await baseAxios.put('/shelf/' + id, body)
  return res.data
}

const remove = async (id) => {
  const res = await baseAxios.delete('/shelf/' + id)
  return res.data
}

const printLabel = async (body) => {
  const res = await baseAxios.post('/shelf/print-label', body)
  return res.data
}

const createImport = async (body) => {
  const res = await baseAxios.post('/shelf/import', body)
  return res.data
}

export default { create, update, getAllPaginate, remove, printLabel, createImport }