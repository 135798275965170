import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import productBrandAPI from '../../services/productBrandAPI';
import productCategoryAPI from '../../services/productCategoryAPI';
import warehouseAPI from '../../services/warehouseAPI';

function FilterSection({ byProduct, byCategory, byBrand, byWarehouse, children, onSubmit }) {
  const params = {
    page: 1,
    limit: 1000,
  }
  const [brand, setBrand] = useState([])
  const [warehouse, setWarehouse] = useState([])
  const [category, setCategory] = useState([])

  useEffect(() => {

    fetchFilterData()
  }, [])

  const sortAsc = (a, b) => {
    if (a.name.toUpperCase() < b.name.toUpperCase()) {
      return -1;
    }
    if (a.name.toUpperCase() > b.name.toUpperCase()) {
      return 1;
    }
    return 0;
  }
  const fetchFilterData = async () => {
    if (byCategory) {
      const res = await productCategoryAPI.getAllPaginate(params)
      setCategory(res.data.productCategory.items.sort(sortAsc),)
    }
    if (byWarehouse) {
      const res = await warehouseAPI.getAllPaginate(params)
      setWarehouse(res.data.warehouse.items.sort(sortAsc))
    }
    if (byBrand) {
      const res = await productBrandAPI.getAllPaginate(params)
      setBrand(res.data.productBrand.items.sort(sortAsc))
    }

  }

  const submitFilter = async (value) => {
    onSubmit(value)
  }
  return (
    <Row justify="space-between">
      <Form
        onFinish={submitFilter}
        layout="inline"
      >
        {byProduct &&
          <Form.Item name="search">
            <Input placeholder="Search data" suffix={<SearchOutlined />} addonBefore={
              <Form.Item name='searchBy'>
                <Select style={{ width: 150 }} placeholder='Search By'>
                  <Select.Option value="name">Product Name</Select.Option>
                  <Select.Option value="sku">SKU</Select.Option>
                </Select>
              </Form.Item>
            } />
          </Form.Item>
        }
        {byWarehouse
          &&
          <Form.Item name="warehouseId">
            <Select placeholder="Select Warehouse">
              {warehouse?.map((item) => (
                <Select.Option value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        {byBrand
          &&
          <Form.Item name="brandId">
            <Select style={{ width: 150 }} placeholder="Select Brand">
              {brand?.map((item) => (
                <Select.Option value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        {byCategory
          &&
          <Form.Item name="categoryId">
            <Select placeholder="Select Category">
              {category?.map((item) => (
                <Select.Option value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        <Button htmlType="submit" type="primary">
          Search
        </Button>
      </Form>
    </Row>
  )
}

export default FilterSection
