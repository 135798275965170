import { Button, Form, Input, message, Modal, Row, Space, Col } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useEffect, useState } from 'react'
import stockAPI from '../../services/stockAPI'

function EditStockModal({ visible, onCancel, onFinish, data }) {
  const [form] = useForm()
  const [loading, setloading] = useState(false)

  const submitForm = async (value) => {
    try {
      const res = await stockAPI.update(value.id, value)
      message.success('Update success')
    } catch (error) {
      message.error('something wrong, please try again later')
    }
    form.resetFields()
    onFinish()
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data, productName: data.product.name, sku: data.product.sku, warehouseName: data.warehouse.name })
    }
  }, [data])

  return (
    <Modal
      onCancel={onCancel}
      visible={visible} title='Edit Stock' maskClosable={false} footer={false}>
      <Form
        form={form}
        layout='vertical'
        onFinish={submitForm}
        autoComplete="off"
      >
        <Row gutter={12}>
          <Col md={14}>
            <Form.Item
              label="Product Name"
              name="productName"
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col md={10}>
            <Form.Item
              label="SKU"
              name="sku"
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Warehouse Name"
          name="warehouseName"
          rules={[{ required: true }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Warehouse"
          hidden="true"
          name="warehouseId"
          rules={[{ required: true }]}
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Pending Stock"
          name="pendingStock"
          rules={[{ required: true }]}
        >
          <Input type='number' min={0} />
        </Form.Item>
        <Form.Item
          label="Available Stock"
          name="availableStock"
          rules={[{ required: true }]}
        >
          <Input type='number' min={0} />
        </Form.Item>
        <Form.Item
          label="Locked Stock"
          name="lockedStock"
          rules={[{ required: true }]}
        >
          <Input type='number' min={0} />
        </Form.Item>
        <Form.Item
          label="Note"
          name="note"
          rules={[{ required: true }]}
        >
          <Input placeholder='Info of Changes' />
        </Form.Item>

        <Form.Item
          name="id"
        >
          <Input type='hidden' />
        </Form.Item>

        <Row justify='end'>
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType='submit' loading={loading} type='primary' >Save</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default EditStockModal
