import { Modal, Form, Input, Switch, message, Row, Space, Button, Select, Typography, Col, notification, Upload, Descriptions, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import productAPI from '../../services/productAPI'
import productBrandAPI from '../../services/productBrandAPI'
import { useSelector } from 'react-redux'
import config from '../../utils/config'
import { UploadOutlined } from '@ant-design/icons'
import Spacer from '../layout/Spacer'
import { currencyFormat } from '../../utils/helper'

function ProductCheckModal({ visible, onCancel, data, onFinish }) {
  useEffect(() => {
    setProduct([])
  }, [visible])

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Batch',
      dataIndex: 'batchCode',
      key: 'batchCode',
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      render: (value) => value.name
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value) => <b>{value}</b>
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'Variant',
      dataIndex: 'attribute',
      key: 'attribute',
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (value, record) => <span>{value.name}</span>
    },


  ]
  const [form] = Form.useForm()
  const [value, setValue] = useState(null)
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState([])
  const onChangeHandler = async (e) => {
    setValue(e.target.value)
  }

  const onEnter = async (e) => {
    setLoading(true)
    if (e.key === 'Enter') {
      try {
        const splitted = value.split('/')
        const res = await productAPI.getOne(splitted[1])

        const newProduct = {
          batchCode: splitted[0],
          ...res.data.product,
        }
        setProduct([newProduct, ...product])

        message.success('Product found')
      } catch (error) {
        message.error('Product not found')
      }
      form.resetFields()
    }
    setLoading(false)

  }

  const exportData = async () => {
    setLoading(true)
    try {
      const res = await productAPI.exportCheck({ products: product })
      window.location.href = res.data.product.Location
    } catch (error) {
      message.error('Export failed')
    }
    setLoading(false)
  }
  return (
    <Modal width={980} title={'Check Product'} maskClosable={false} visible={visible} footer={false} onCancel={onCancel}>
      <Form
        form={form}
        layout='vertical'
        autoComplete="off"
      >

        <Form.Item
          label="Scan Here"
          name="scan"
        >
          <Input placeholder='click here to scan...' autoFocus={true} rows={2} onChange={onChangeHandler} onKeyUp={onEnter} />
        </Form.Item>
      </Form>
      <p>Product Detail</p>
      <div >

        <Table
          columns={tableColumns}
          pagination={false}
          dataSource={product}
          loading={loading}
          // rowKey='id'
          scroll={{ y: 320 }}
        />
      </div>
      <Spacer />

      <Row justify='end'>
        <Space>
          <Button onClick={() => setProduct([])} loading={loading}>Clear</Button>
          <Button onClick={exportData} type='primary' loading={loading}>Export</Button>
        </Space>
      </Row>

    </Modal>
  )
}

export default ProductCheckModal
