/** @jsxImportSource @emotion/react */

import { DownOutlined, EditOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Card, Col, Form, Select, Input, Row, Typography, Button, Menu, Dropdown, Space, Tabs, Table, Modal, Tag, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Spacer from '../../components/layout/Spacer'
import ShelfFormModal from '../../components/shelf/ShelfFormModal'
import rackAPI from '../../services/rackAPI'
import shelfAPI from '../../services/shelfAPI'
import { utcToLocal } from '../../utils/helper'
import ShelfImportModal from '../../components/warehouse/ShelfImportModal'

function ShelfPage() {
  const tableColumns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value) => <b>{value}</b>
    },

    {
      title: 'Status',
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      render: (val) => val ? <Tag color='red'>Inactive</Tag> : <Tag color='green'>Active</Tag>
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => <span>{utcToLocal(value)}</span>
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) =>
        <Space direction="vertical">
          <Button type='link' size='small' onClick={() => selectData(record)}>Edit</Button>
          <Button type='link' size='small' onClick={() => deleteConfirm(value)}>Delete</Button>
          <Button type='link' size='small' onClick={() => printLabel(value)}>Print Label</Button>
        </Space>
    },
  ];

  const menu = (
    <Menu >
      <Menu.Item key="1" onClick={() => setModal(true)}>
        Add shelf
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setCreateModal(true)}>
        Import from file
      </Menu.Item>
    </Menu>
  );
  const [createModal, setCreateModal] = useState(false)

  const [modal, setModal] = useState(false)
  const [loading, setloading] = useState(false)
  const [shelf, setShelf] = useState({
    data: [],
    selected: null,
  })
  const [rack, setRack] = useState(null)
  const routeParams = useParams()

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 25,
    total: 0
  })

  useEffect(() => {
    fetchData(pagination)
  }, [])

  const selectData = (record) => {
    setShelf({ ...shelf, selected: record })
    setModal(true)
  }

  const deleteConfirm = (id) => {
    Modal.confirm({
      title: 'Do you Want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone',
      onOk: async () => {
        const res = await shelfAPI.remove(id)
        message.success('Delete success')
        fetchData({ current: 1, pageSize: 10 })
      },
    });
  }
  const fetchData = async (pagination, filters) => {
    setloading(true)
    const params = {
      ...filters,
      page: pagination.current,
      limit: pagination.pageSize,
    }

    let res = await rackAPI.getOne(routeParams.rackId)
    setRack(res.data.rack)

    res = await rackAPI.getShelf(routeParams.rackId, params)
    setShelf({
      data: res.data.shelf.items,
      selected: null,
    })
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: res.data.shelf.meta.totalItems
    })
    setloading(false)
  }

  const submitFilter = (value) => {
    fetchData({ current: 1, pageSize: 10 }, value)
  }

  const printLabel = async (id = false) => {
    setloading(true)

    try {
      let res
      // single print
      if (id) {
        res = await shelfAPI.printLabel({ id: [id] })
      }
      // bulk print
      else {
        res = await shelfAPI.printLabel({ id: shelf.data.map(item => item.id) })
      }
      window.open(res.data.Location, '_blank');

    } catch (error) {
      message.error('something wrong')
    }
    setloading(false)
  }
  return (
    <div>
      <Typography.Title level={3}>Shelf Management - Rack: {rack?.code}</Typography.Title>
      <Card>
        <Row justify='space-between'>
          <Col md={16}>
            <Form onFinish={submitFilter} layout='inline'>
              <Form.Item
                name="search"
                required
              >
                <Input placeholder='Cari data..' suffix={<SearchOutlined />} addonBefore={
                  <Select defaultValue="name">
                    <Select.Option value="name">Shelf Name</Select.Option>
                  </Select>
                } />
              </Form.Item>


              <Button htmlType='submit' type='primary'>Search</Button>
            </Form>
          </Col>
          <Space>
            <Dropdown overlay={menu}>
              <Button type='primary' >Add Shelf<DownOutlined /></Button>
            </Dropdown>
            <Button onClick={() => printLabel()} type='primary' loading={loading}>Print All Label</Button>
          </Space>
        </Row>

      </Card>
      <Spacer />
      <Card>
        <Table
          columns={tableColumns}
          pagination={pagination}
          onChange={(pagination, filter, sorter) => fetchData(pagination)}
          dataSource={shelf.data}
          loading={loading}
          rowKey='id' scroll={{ x: true }}
        />
      </Card>

      <ShelfFormModal
        visible={modal}
        data={shelf.selected}
        onCancel={() => {
          setModal(false)
          setShelf({ ...shelf, selected: null })
        }}
        onFinish={() => {
          setShelf({ ...shelf, selected: null })
          setModal(false)
          fetchData(pagination)
        }}
        rackId={routeParams.rackId}

      />
      <ShelfImportModal
        rack={rack}
        onCancel={() => {
          setCreateModal(false)
        }}
        onFinish={() => {
          setCreateModal(false)
          fetchData(pagination)

        }}
        visible={createModal}
      />
    </div>
  )
}

export default ShelfPage
