import Theme from "../Theme";
import Size from "./Size";

export default {
  sm: {
    fontSize: Size.sm
  },
  md: {
    fontSize: Size.md
  },
  lg: {
    fontSize: Size.lg
  },
  xl: {
    fontSize: Size.xl
  },
  xxl: {
    fontSize: Size.xxl
  },
  white: {
    color: Theme.white
  },
  primary: {
    color: Theme.orange
  },
  secondary: {
    color: Theme.darkGray
  }
}