import React, { useEffect, useState } from 'react'
import { Modal, Form, Row, Space, Button, Timeline } from 'antd'
import Spacer from '../layout/Spacer'
import outboundAPI from '../../services/outboundAPI'

function OutboundStatusModal({ visible, onCancel, data }) {
  const [form] = Form.useForm()
  const [statusHistory, setStatusHistory] = useState([])

  const fetchStatusHistory = async () => {
    const res = await outboundAPI.getStatusHistory(data.id)
    setStatusHistory(res.data.outbound.statusHistory)
  }

  useEffect(() => {
    form.resetFields()
    if (data) {
      fetchStatusHistory()
    }
  }, [data, visible])

  return (
    <Modal title='Status History' visible={visible} maskClosable={false} footer={false} onCancel={onCancel}>
      <Row>
        <Timeline>
          {statusHistory.map((item) => (
            <Timeline.Item>{item}</Timeline.Item>
          ))}
        </Timeline>
      </Row>
      <Spacer />
      <Row justify='end'>
        <Space>
          <Button onClick={onCancel}>Close</Button>
        </Space>
      </Row>
    </Modal >
  )
}

export default OutboundStatusModal
