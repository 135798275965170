import React from 'react'

function LostPage() {
  return (
    <div>
      YOU RE LOST
    </div>
  )
}

export default LostPage
