import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  shops: [],
}

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setShops: (state, action) => {
      state.shops = action.payload.shops
    },
  },
})

export const { setShops } = shopSlice.actions

export default shopSlice.reducer