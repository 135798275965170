import { TagOutlined, UsergroupDeleteOutlined, UserOutlined } from '@ant-design/icons'
import { Card, Col, Row, Space, Typography } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { Font } from '../../styles'

function SettingPage() {
  return (
    <div>
      <Typography.Title level={2}>Setting</Typography.Title >
      <Row gutter={[16, 16]} >
        <Col md={8} >
          <Link to='/setting/staff'>
            <Card>
              <Space align='center' size='large'>
                <UserOutlined style={Font.xxl} />
                <div>
                  <h3>Staff</h3>
                  <p>Manage Staff</p>
                </div>
              </Space>
            </Card>
          </Link>
        </Col>
        <Col md={8}>
          <Link to='/setting/role-management'>
            <Card>
              <Space align='center' size='large'>
                <UsergroupDeleteOutlined style={Font.xxl} />
                <div>
                  <h3>Role Management</h3>
                  <p>Manage Role</p>
                </div>
              </Space>
            </Card>
          </Link>
        </Col>
        <Col md={8}>
          <Card>
            <Space align='center' size='large'>
              <TagOutlined style={Font.xxl} />
              <div>
                <h3>Product Setting</h3>
                <p>Setting for product</p>
              </div>
            </Space>
          </Card>
        </Col>
      </Row>
    </div >
  )
}

export default SettingPage
