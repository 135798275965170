import { Button, Checkbox, Form, message, Modal, Row } from 'antd';
import React, { useState } from 'react'
import outboundAPI from '../../services/outboundAPI';

const defaultCheckedList = ['jne', 'sicepat', 'j&t', 'ninja', 'shopee', 'other']
function OutboundPrintModal({ visible, onCancel, onFinish, data }) {
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const [loading, setLoading] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);

  const printLabel = async (val) => {
    setLoading(true)
    if (checkedList.length == 0) {
      return message.error('Please select courier at least 1')
    }
    try {
      const res = await outboundAPI.printLabel({
        batchId: data?.id,
        couriers: checkedList,
        allCourier: checkAll
      })
      window.open(res.data.Location, '_blank');
      onFinish()
    } catch (error) {
      message.error('No outbounds found')
    }
    setLoading(false)

  }
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < defaultCheckedList.length);
    setCheckAll(list.length === defaultCheckedList.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? defaultCheckedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <Modal title={`Print Batch #${data?.id}`} visible={visible} onCancel={onCancel} footer={null}>
      <Form onFinish={printLabel} layout='vertical'>

        <Form.Item label='Courier' >
          <Checkbox.Group options={defaultCheckedList} value={checkedList} onChange={onChange} />
        </Form.Item>

        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          Check all
        </Checkbox>
        <Row justify="end">
          <Button type="primary" htmlType='submit' loading={loading}> Print</Button>

        </Row>
      </Form>
    </Modal>
  )
}

export default OutboundPrintModal
