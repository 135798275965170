import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Card, Col, Form, Select, Input, Row, Typography, Button, Space, Table, Modal, Tag, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Spacer from '../../components/layout/Spacer'
import WarehouseFormModal from '../../components/warehouse/WarehouseFormModal'
import warehouseAPI from '../../services/warehouseAPI'
import { utcToLocal } from '../../utils/helper'
import { INITIAL_PAGINATION } from '../../utils/constant'

function WarehousePage() {
  const tableColumns = [
    {
      title: 'Warehouse Name',
      dataIndex: 'name',
      key: 'name',
      render: (value) => <b>{value}</b>
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Status',
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      render: (val) => val ? <Tag color='red'>Inactive</Tag> : <Tag color='green'>Active</Tag>
    },
    {
      title: 'Warehouse Head',
      dataIndex: 'warehouseHead',
      key: 'warehouseHead',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => <span>{utcToLocal(value)}</span>
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) => 
      <Space direction="vertical">
        <Button type='link' size='small' onClick={() => selectData(record)}>Edit</Button>
        <Button type='link' size='small' onClick={() => deleteConfirm(value)}>Delete</Button>
        <Link to={`/warehouse/${value}/rack`}>
          <Button type='link' size='small' >Racks </Button>
        </Link>
      </Space>
    },
  ];

  const [modal, setModal] = useState(false)
  const [loading, setloading] = useState(false)
  const [warehouse, setWarehouse] = useState({
    data: [],
    selected: null,
  })

  const [pagination, setPagination] = useState(INITIAL_PAGINATION)

  useEffect(() => {
    fetchData(pagination)
  }, [])

  const deleteConfirm = (id) => {
    Modal.confirm({
      title: 'Do you Want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone',
      onOk: async () => {
        const res = await warehouseAPI.remove(id)
        message.success('Delete success')
        fetchData({ current: 1, pageSize: 10 })
      },
    });
  }

  const selectData = (record) => {
    setWarehouse({ ...warehouse, selected: record })
    setModal(true)
  }

  const fetchData = async (pagination, filters) => {
    setloading(true)
    const params = {
      ...filters,
      page: pagination.current,
      limit: pagination.pageSize,
    }

    const res = await warehouseAPI.getAllPaginate(params)
    setWarehouse({
      data: res.data.warehouse.items,
      selected: null,
    })
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: res.data.warehouse.meta.totalItems
    })
    setloading(false)
  }

  const submitFilter = (value) => {
    fetchData({ current: 1, pageSize: 10 }, value)
  }

  return (
    <div>
      <Typography.Title level={2}>Warehouse Management</Typography.Title>
      <Card>
        <Row justify='space-between'>
          <Col md={18}>
            <Form onFinish={submitFilter} layout='inline'>
              <Form.Item
                name="search"
                required
              >
                <Input placeholder='Cari data..' suffix={<SearchOutlined />} addonBefore={
                  <Select defaultValue="name">
                    <Select.Option value="name">Warehouse Name</Select.Option>
                  </Select>
                } />
              </Form.Item>


              <Button htmlType='submit' type='primary'>Search</Button>
            </Form>
          </Col>

          <Button onClick={() => setModal(true)} type='primary' >Add Warehouse</Button>
        </Row>

      </Card>
      <Spacer />
      <Card>
        <Table
          columns={tableColumns}
          pagination={pagination}
          onChange={(pagination, filter, sorter) => fetchData(pagination)}
          dataSource={warehouse.data}
          loading={loading}
          rowKey='id' scroll={{ x: true }}
        />
      </Card>

      <WarehouseFormModal
        visible={modal}
        data={warehouse.selected}
        onCancel={() => {
          setModal(false)
          setWarehouse({ ...warehouse, selected: null })
        }}
        onFinish={() => {
          setWarehouse({ ...warehouse, selected: null })
          setModal(false)
          fetchData(pagination)
        }}
      />
    </div>
  )
}

export default WarehousePage
