import { baseAxios } from "./axios"

const getOutboundStatus = async (params = {}) => {
  const res = await baseAxios.get('/outbound/dashboard/outbound-status', { params })
  return res.data
}

const getInboundStatus = async (params = {}) => {
  const res = await baseAxios.get('/inbound/dashboard/inbound-status', { params })
  return res.data
}

const getChannelsDistribution = async (params = {}) => {
  const res = await baseAxios.get('/outbound/dashboard/channel-distribution', { params })
  return res.data
}

const getTopProduct = async (params = {}) => {
  const res = await baseAxios.get('/outbound/dashboard/most-product', { params })
  return res.data
}

const getOutOfStock = async (params = {}) => {
  const res = await baseAxios.get('/stock/dashboard/out-of-stock', { params })
  return res.data
}

const getInboundOutbound = async (params = {}) => {
  const res = await baseAxios.get('/dashboard/line-chart', { params })
  return res.data
}

const getQuantityOutbound = async (params = {}) => {
  const res = await baseAxios.get('/outbound/dashboard/bar-chart', { params })
  return res.data
}

const getQuantityInbound = async (params = {}) => {
  const res = await baseAxios.get('/inbound/dashboard/bar-chart', { params })
  return res.data
}

export default {getOutboundStatus, getInboundStatus, getChannelsDistribution, getTopProduct, getOutOfStock, getInboundOutbound, getQuantityOutbound, getQuantityInbound}
