import React from 'react'
import { useLocation, Navigate } from 'react-router';
import { useSelector } from 'react-redux'
import jwt from 'jsonwebtoken'
import dayjs from 'dayjs';
import config from '../../config';

const checkAuth = (auth) => {
  try {
    const decodedToken = jwt.verify(auth.token, config.SECRET_KEY)
    if (dayjs().unix() < decodedToken.exp) {
      return true
    }
  } catch (error) {
    return false
  }
}

const ProtectedRoute = ({ children, ...rest }) => {
  const auth = useSelector(state => state.auth)
  const isAuthenticated = checkAuth(auth)

  if (!isAuthenticated) {
    window.location.replace('/login')
  }
  return children

}


export default ProtectedRoute