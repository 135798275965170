/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Select,
  Input,
  Row,
  Typography,
  Button,
  Space,
  Table,
  Modal,
  message,
  Menu,
  Dropdown,
  Tag,
} from "antd";
import Spacer from "../../components/layout/Spacer";
import { sumTotal, utcToLocal } from "../../utils/helper";
import { ExclamationCircleOutlined, SearchOutlined, DownOutlined } from "@ant-design/icons";
import productBrandAPI from "../../services/productBrandAPI";
import inboundAPI from '../../services/inboundAPI'
import DatePicker from "../../components/antdcustom/DatePicker";
import InboundDetailModal from "../../components/inbound/InboundDetailModal";
import InboundFormModal from "../../components/inbound/InboundFormModal";
import dayjs from 'dayjs';
import TimeDetail from "../../components/common/TimeDetail";
import config from "../../utils/config";
import { useSelector } from "react-redux";
import { CRUD } from "../../utils/accessRight";
import { INITIAL_PAGINATION } from "../../utils/constant";
import InboundApproveModal from "../../components/inbound/InboundApproveModal";
import warehouseAPI from "../../services/warehouseAPI";

function InboundPage({ visible, data }) {
  const roleName = useSelector((state) => state.auth.user.role.name);

  const tableColumns = [
    {
      title: "Batch Code",
      dataIndex: "code",
      key: "code",
      render: (value, record) => <span>#{(value || record.id)}</span>,
    },
    {
      title: "Products",
      dataIndex: "inbounds",
      key: "inbounds",
      render: (value) =>
        <ul style={{ overflowY: 'scroll', maxHeight: '100px' }} >
          {value.map((inbound) => (
            <li key={inbound.id}><b>{inbound.product.sku}</b> - {inbound.product.name} ({inbound.expectedQuantity}x)</li>))}
        </ul>
    },
    {
      title: "Brand",
      dataIndex: "inbounds",
      key: "inbounds",
      render: (value) => <b>{value[0].product.brand.name}</b>,
    },
    {
      title: "Arrive At",
      dataIndex: "arriveAt",
      key: "arriveAt",
      render: (value) => <span>{utcToLocal(value)}</span>,
    },
    {
      title: "Printed",
      dataIndex: "isPrinted",
      key: "isPrinted",
      render: (value, record) => value ? <Tag color='green'>Printed</Tag> : <Tag color='red'>Not Printed</Tag>
    },
    {
      title: "Expected/Received/Put (Quantity)",
      dataIndex: "totalQuantity",
      key: "totalQuantity",
      render: (value, record) => `${value}/${sumTotal(record.inbounds, 'receivedQuantity')}/${sumTotal(record.inbounds, 'putQuantity')}`
    },
    {
      title: "Warehouse",
      dataIndex: "inbounds",
      key: "inbounds",
      render: (value) => <span>{value[0].warehouse.name}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) =>
        value === 'pending' ? <Tag color='gray'>Pending</Tag> :
          value === 'done' ? <Tag color='green'>Done</Tag> :
            value === 'ready putaway' ? <Tag color='orange'>Ready Putaway</Tag> :
              <Tag color='red'>Cancelled</Tag>
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value, record) => <TimeDetail createdAt={value} updatedAt={record.updatedAt} />
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (value, record) => (
        <Space direction="vertical">
          <Button type="link" size="small" onClick={() => selectDetail(record)}>
            Detail
          </Button>
          <Button type="link" size="small" onClick={() => selectData(record)}>
            Edit
          </Button>
          {
            record.status === "pending" && CRUD.includes(roleName) ?
              <Button type="link" size="small" onClick={() => approveConfirm(record)}>
                Approve
              </Button>
              : null
          }
          {
            record.status === "pending" && CRUD.includes(roleName) ?
              <Button type="link" size="small" onClick={() => cancelConfirm(value)}>
                Cancel
              </Button>
              : null
          }
          {
            record.status != "canceled" &&
            <Button type="link" size="small" onClick={() => printLabel(value)}>
              Print label
            </Button>
          }
        </Space>
      ),
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setCreateModal(true)}>
        Import File
      </Menu.Item>
      <Menu.Item key="2">
        <a href={`${config.AWS_S3_URL}/template/adawms-inbound-template.xlsx`}>
          Download template file
        </a>
      </Menu.Item>
    </Menu>
  );

  const [form] = Form.useForm()

  const [loading, setloading] = useState(false);

  const [productBrand, setProductBrand] = useState({
    data: [],
    selected: null,
  });

  const [warehouse, setWarehouse] = useState({
    data: [],
    selected: null
  })

  const [inbound, setInbound] = useState({
    data: [],
    selected: null
  })

  const [createModal, setCreateModal] = useState(false)

  const [detailModal, setDetailModal] = useState(false)

  const [approveModal, setApproveModal] = useState(false)

  const [pagination, setPagination] = useState(INITIAL_PAGINATION);

  const [filters, setFilters] = useState({
    startDate: dayjs().add(-7, 'day').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  });

  const selectData = (record) => {
    return alert('Edit feature coming soon')
    setInbound({ ...inbound, selected: record });
    setCreateModal(true)
  };

  const selectDetail = (record) => {
    setInbound({ ...inbound, selected: record });
    setDetailModal(true)
  };

  const printLabel = async (id) => {
    setloading(true)
    try {
      const res = await inboundAPI.printLabel(id)
      window.open(res.data.Location, '_blank');

    } catch (error) {
      message.error('Something wrong')
    }
    setloading(false)

  }
  const approveConfirm = (record) => {
    setInbound({ ...inbound, selected: record });
    setApproveModal(true)
  };
  const cancelConfirm = (id) => {
    Modal.confirm({
      title: "Do you want to cancel this inbound?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone",
      onOk: async () => {
        await inboundAPI.cancelBatch(id);
        message.success("Cancel success");
        fetchInbound(pagination, filters);
      },
    });
  };

  const fetchInbound = async (pagination, filters) => {
    setloading(true);
    let params = {
      ...filters,
      page: pagination.current,
      limit: pagination.pageSize,
      includes: ['items'],
    };

    const res = await inboundAPI.getAllBatchPaginate(params);
    setInbound({
      data: res.data.inboundBatch.items,
      selected: null
    })

    setPagination({
      current: res.data.inboundBatch.meta.currentPage,
      pageSize: res.data.inboundBatch.meta.itemsPerPage,
      total: res.data.inboundBatch.meta.totalItems,
    });
    setloading(false);
  };

  const fetchBrand = async () => {
    setloading(true)
    const params = {
      page: 1,
      limit: 1000,
    }
    const res = await productBrandAPI.getAllPaginate(params)
    const sortBrand = res.data.productBrand.items.sort((a, b) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      }
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }
      return 0;
    });
    setProductBrand({
      data: sortBrand,
      selected: null,
    })

    setloading(false)
  }

  const fetchWarehouse = async () => {
    setloading(true)
    const params = {
      page: 1,
      limit: 1000,
    }
    const res = await warehouseAPI.getAllPaginate(params)
    setWarehouse({
      data: res.data.warehouse.items,
      selected: null,
    })
    setloading(false)
  }

  const submitFilter = (value) => {
    const data = {
      ...value,
      startDate: value.date[0].format('YYYY-MM-DD'),
      endDate: value.date[1].format('YYYY-MM-DD'),
    }

    setFilters(data)
  };

  const exportInbound = async (filters) => {
    setloading(true)
    let params = {
      ...filters,
    };
    const res = await inboundAPI.exports(params)
    window.location.href = res.data.inbound.Location
    setloading(false)
  }

  useEffect(() => {
    fetchInbound(INITIAL_PAGINATION, filters);
    setPagination(INITIAL_PAGINATION)
  }, [filters]);

  useEffect(() => {
    fetchBrand();
    fetchWarehouse()
    form.resetFields()
  }, []);

  return (
    <div>
      <Typography.Title level={2}>Inbound</Typography.Title>
      <Card
        title="List of Inbound"
        extra={
          <Space>
            <Button
              type="primary"
              loading={loading}
              onClick={() => exportInbound(filters)}
            > Export </Button>
            {CRUD.includes(roleName) ?
              <Dropdown overlay={menu}>
                <Button type="primary">
                  New Inbound <DownOutlined />
                </Button>
              </Dropdown>
              : null
            }
          </Space>
        }
      >
        <Row justify="space-between">
          <Form
            onFinish={submitFilter}
            layout="inline"
            initialValues={{ date: [dayjs().add(-7, 'day'), dayjs()] }}
          >
            <Form.Item name="date" label="Inbound Date" required>
              <DatePicker.RangePicker />
            </Form.Item>
            <Form.Item name="brandId" label="Brand">
              <Select placeholder="Select Brand">
                {productBrand.data.map((item) => (
                  <Select.Option value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="status" label="Status">
              <Select placeholder="Select Status">
                <Select.Option value="">All Status</Select.Option>
                <Select.Option value="pending">Pending</Select.Option>
                <Select.Option value="ready putaway">Ready Putaway</Select.Option>
                <Select.Option value="done">Done</Select.Option>
                <Select.Option value="canceled">Canceled</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="warehouseId" label="Warehouse">
              <Select placeholder="Select Warehouse">
                {warehouse.data.map((item) => (
                  <Select.Option value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="search" label="Search">
              <Input placeholder="Batch ID..." suffix={<SearchOutlined />} />
            </Form.Item>
            <Button htmlType="submit" type="primary">
              Search
            </Button>
          </Form>
        </Row>
      </Card>
      <Spacer />
      <Card>
        <Table
          columns={tableColumns}
          pagination={pagination}
          onChange={(pagination, _filter, _sorter) => fetchInbound(pagination, filters)}
          dataSource={inbound.data}
          loading={loading}
          scroll={{ x: true }}
        />
      </Card>

      <InboundFormModal
        onFinish={() => {
          setInbound({ ...inbound, selected: null })
          fetchInbound(pagination, filters)
          setCreateModal(false)
        }}
        data={inbound.selected}
        brand={productBrand.data}
        onCancel={() => {
          setCreateModal(false)
          setInbound({ ...inbound, selected: null })
        }}
        visible={createModal}
      />

      <InboundDetailModal
        visible={detailModal}
        data={inbound.selected}
        onCancel={() => {
          setDetailModal(false)
          setInbound({ ...inbound, selected: null })
        }}
      />

      <InboundApproveModal
        onFinish={() => {
          fetchInbound(pagination, filters)
          setApproveModal(false)
        }}
        visible={approveModal}
        data={inbound.selected}
        onCancel={() => {
          setApproveModal(false)
          setInbound({ ...inbound, selected: null })
        }}
      />
    </div>
  );
}

export default InboundPage;
