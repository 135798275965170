/** @jsxImportSource @emotion/react */
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { SearchOutlined } from '@ant-design/icons'
import { Card, Col, Form, Select, Input, Row, Typography, Button, Space, Table, Modal, message } from 'antd'
import React, { useEffect, useState } from 'react'
import Spacer from '../../components/layout/Spacer'
import StaffFormModal from '../../components/setting/StaffFormModal'
import userAPI from '../../services/userAPI'
import { utcToLocal } from '../../utils/helper'

function StaffPage() {
  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value) => <b>{value}</b>
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (value) => <span>{value?.name}</span>
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => <span>{utcToLocal(value)}</span>
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (value, record) => (
        <Space>
          <Button type="link" size="small" onClick={() => selectData(record)}>
            Edit
          </Button>
          <Button type="link" size="small" onClick={() => deleteData(value)} >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const [modal, setModal] = useState(false)
  const [loading, setloading] = useState(false)
  const [staff, setStaff] = useState({
    data: [],
    selected: null,
  })

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  })

  useEffect(() => {
    fetchData(pagination)
  }, [])

  const selectData = (record) => {
    setStaff({ ...staff, selected: record })
    setModal(true)
  }
  const deleteData = (id) => {
    Modal.confirm({
      title: "Do you Want to delete this user?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone",
      onOk: async () => {
        await userAPI.deleteStaff(id);
        message.success("Delete success");
        fetchData({ current: 1, pageSize: 10 });
      },
    });
  };

  const fetchData = async (pagination, filters) => {
    setloading(true)
    const params = {
      ...filters,
      page: pagination.current,
      limit: pagination.pageSize,
    }

    const res = await userAPI.getAllStaffPaginate(params)
    setStaff({
      data: res.data.staff.items,
      selected: null,
    })
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: res.data.staff.meta.totalItems
    })
    setloading(false)
  }

  const submitFilter = (value) => {
    fetchData({ current: 1, pageSize: 10 }, value)
  }

  return (
    <div>
      <Typography.Title>Staff Management</Typography.Title>
      <Card>
        <Row justify='space-between'>
          <Col md={18}>
            <Form onFinish={submitFilter} layout='inline'>
              <Form.Item
                name="search"
                required
              >
                <Input placeholder='Cari data..' suffix={<SearchOutlined />} addonBefore={
                  <Select defaultValue="name">
                    <Select.Option value="name">Staff Name</Select.Option>
                  </Select>
                } />
              </Form.Item>
              <Button htmlType='submit' type='primary'>Search</Button>
            </Form>
          </Col>
          <Button onClick={() => setModal(true)} type='primary' >Add Staff</Button>
        </Row>
      </Card>
      <Spacer />
      <Card>
        <Table
          columns={tableColumns}
          pagination={pagination}
          onChange={(pagination, filter, sorter) => fetchData(pagination)}
          dataSource={staff.data}
          loading={loading}
          rowKey='id' scroll={{ x: true }}
        />
      </Card>

      <StaffFormModal
        visible={modal}
        data={staff.selected}
        onCancel={() => {
          setModal(false)
          setStaff({ ...staff, selected: null })
        }}
        onFinish={() => {
          setStaff({ ...staff, selected: null })
          setModal(false)
          fetchData(pagination)
        }}
      />
    </div>
  )
}

export default StaffPage