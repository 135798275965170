/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Select,
  Row,
  Typography,
  Button,
  Space,
  Table,
  Tag,
} from "antd";
import Spacer from "../../components/layout/Spacer";
import stockAPI from "../../services/stockAPI"
import { INITIAL_PAGINATION } from "../../utils/constant";
import TimeDetail from "../../components/common/TimeDetail";
import StockOpnameApproveModal from "../../components/stock/StockOpnameApproveModal";
import { CRUD } from "../../utils/accessRight";
import { useSelector } from "react-redux";
import StockOpnameStatusModal from "../../components/stock/StockOpnameStatusModal";

function StockOpnamePage() {
  const roleName = useSelector((state) => state.auth.user.role.name);

  const tableColumns = [
    {
      title: "Batch",
      dataIndex: "id",
      key: "id",
      render: (value) => <b>{value}</b>
    },
    {
      title: "Shelf",
      dataIndex: "shelf",
      key: "shelf",
      width: 80,
      render: (value) => <span>{value.code}</span>
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) =>
      value === 'in progress' ? <Tag color='gray'>In Progress</Tag> :
      <Tag color='green'>Done</Tag>
    },
    {
      title: "Actual/Expected (Quantity)",
      dataIndex: "actualQuantity",
      key: "actualQuantity",
      width: 80,
      render: (value, record) => `${value}/${record.expectedQuantity}`
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value, record) => <TimeDetail createdAt={value} updatedAt={record.updatedAt} />
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (value, record) => (
        <Space direction="vertical">
          {record.status === 'in progress' && CRUD.includes(roleName) ?
            <Button type="link" onClick={() => selectData(record)} size="small" >
              Approve
            </Button>
            : null
          }
          <Button type="link" size="small" onClick={() => selectStatus(record)}>
            Status History
          </Button>
        </Space>
      ),
    },
  ];

  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false);

  const [opname, setOpname] = useState({
    data: [],
    selected: null
  })

  const [approveModal, setApproveModal] = useState(false)

  const [statusModal, setStatusModal] = useState(false)

  const [pagination, setPagination] = useState(INITIAL_PAGINATION);

  const [filters, setFilters] = useState({});

  const fetchStockOpname = async (pagination, filters) => {
    setLoading(true)
    const params = {
      ...filters,
      page: pagination.current,
      limit: pagination.pageSize,
    }
    const res = await stockAPI.stockOpname(params)
    setOpname({
      data: res.data.stockOpname.items,
      selected: null,
    })
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: res.data.stockOpname.meta.totalItems
    })
    setLoading(false)
  }

  const selectData = (record) => {
    setOpname({ ...opname, selected: record })
    setApproveModal(true)
  }

  const selectStatus = (record) => {
    setOpname({ ...opname, selected: record })
    setStatusModal(true)
  };

  // const exportReportStock = async (filters) => {
  //   setLoading(true)
  //   let params = {
  //     ...filters,
  //   };
  //   const res = await stockAPI.exportStockReport(params)
  //   window.location.href = res.data.stocks.Location
  //   setLoading(false)
  // }

  const submitFilter = (value) => {
    const data = {
      ...value
    }
    setFilters(data)
  };

  useEffect(() => {
    fetchStockOpname(INITIAL_PAGINATION, filters)
    setPagination(INITIAL_PAGINATION)
  }, [filters]);

  useEffect(() => {
    form.resetFields()
  }, [])

  return (
    <div>
      <Typography.Title level={2}>Stock Opname</Typography.Title>
      <Card
        title="List of Stock Opname"
        // extra={
        //   <Button type="primary" loading={loading} onClick={() => exportReportStock(filters)}>
        //     Export
        //   </Button>
        // }
      >
        <Row justify="space-between">
          <Form
            onFinish={submitFilter}
            layout="inline"
          >
            <Form.Item name="status">
              <Select placeholder='Select Status'>
                <Select.Option value="in progress">In Progress</Select.Option>
                <Select.Option value="done">Done</Select.Option>
              </Select>
            </Form.Item>
            <Button htmlType="submit" type="primary">
              Search
            </Button>
          </Form>
        </Row>
      </Card>
      <Spacer />
      <Card>
        <Table
          columns={tableColumns}
          pagination={pagination}
          onChange={(pagination, _filter, _sorter) => fetchStockOpname(pagination, filters)}
          dataSource={opname.data}
          loading={loading}
          scroll={{ x: true }}
        />
      </Card>
      <StockOpnameApproveModal
        onFinish={() => {
          fetchStockOpname(pagination, filters)
          setApproveModal(false)
        }}
        visible={approveModal}
        data={opname.selected}
        onCancel={() => {
          setApproveModal(false)
        }}
      />

      <StockOpnameStatusModal
        visible={statusModal}
        data={opname.selected}
        onCancel={() => {
          setStatusModal(false)
        }}
      />
    </div>
  );
}
export default StockOpnamePage;