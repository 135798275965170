import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginPage from "./pages/LoginPage";
import configureStore from "./redux/store";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import GuestRoute from "./components/routes/GuestRoute";
import DashboardPage from "./pages/DashboardPage";
import LostPage from "./pages/LostPage";
import "./App.less";
import WarehousePage from "./pages/warehouse/WarehousePage";
import AdawmsLayout from "./components/layout/AdawmsLayout";
import RegisterPage from "./pages/RegisterPage";
import SettingPage from "./pages/setting/SettingPage";
import StaffPage from "./pages/setting/StaffPage";
import RoleManagementPage from "./pages/setting/RoleManagementPage";
import RackPage from "./pages/rack/RackPage";
import ShelfPage from "./pages/shelf/ShelfPage";
import ProductCategoryPage from "./pages/product/ProductCategoryPage";
import ProductBrandPage from "./pages/product/ProductBrandPage";
import ProductPage from "./pages/product/ProductPage";
import InboundPage from "./pages/inbound/InboundPage";
import OutboundPage from "./pages/outbound/OutboundPage";
import LocationPage from "./pages/location/LocationPage";
import StockPage from "./pages/stock/StockPage";
import ReportPage from "./pages/report/ReportPage";
import StockHistoryPage from "./pages/stock/StockHistoryPage";
import OutboundBatchPage from "./pages/outbound/OutboundBatchPage";
import StockOpnamePage from "./pages/stock/StockOpnamePage";
import { ALL_ACCESS, DASHBOARD, REPORT, IN_OUT, CRUD } from "./utils/accessRight";

function App() {
  const roleName = useSelector((state) => state.auth.user?.role.name);
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <GuestRoute>
            <LoginPage />
          </GuestRoute>
        }
      />
      <Route
        path="/register"
        element={
          <GuestRoute>
            <RegisterPage />
          </GuestRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <AdawmsLayout />
          </ProtectedRoute>
        }
      >
        <Route index path="/dashboard" element={ DASHBOARD.includes(roleName) ? <DashboardPage /> : <Navigate to="/404" />} />
        <Route path="/" element={ DASHBOARD.includes(roleName) ? <Navigate to="/dashboard" /> : <Navigate to="/404" />} />
        {/* <Route path='/stock' >
          <Route index element={
            <StockManagement />
          } />

          <Route path='edit/:productVariantId' element={
            <EditStockPage />
          }
          />

          <Route path='history/:productVariantId' element={
            <StockHistoryPage />
          }
          />
        </Route> */}
        <Route path="/warehouse">
          <Route index element={ALL_ACCESS.includes(roleName) ? <WarehousePage /> : <Navigate to="/404" />} />
          <Route path=":warehouseId/rack" element={ALL_ACCESS.includes(roleName) ? <RackPage /> : <Navigate to="/404" />} />
          <Route
            path=":warehouseId/rack/:rackId/shelf"
            element={ALL_ACCESS.includes(roleName) ? <ShelfPage /> : <Navigate to="/404" />}
          />
        </Route>
        <Route path="/product">
          <Route index element={CRUD.includes(roleName) ? <ProductPage /> : <Navigate to="/404" />} />
          <Route path="category" element={CRUD.includes(roleName) ? <ProductCategoryPage /> : <Navigate to="/404" />} />
          <Route path="brand" element={CRUD.includes(roleName) ? <ProductBrandPage /> : <Navigate to="/404" />} />
          <Route path=":productId/stock-history" element={CRUD.includes(roleName) ? <StockHistoryPage /> : <Navigate to="/404" />} />
          <Route path=":productId/location" element={CRUD.includes(roleName) ? <LocationPage /> : <Navigate to="/404" />} />
        </Route>
        <Route path="/stock" element={CRUD.includes(roleName) ? <StockPage /> : <Navigate to="/404" />} />
        <Route path="/stock/opname" element={CRUD.includes(roleName) ? <StockOpnamePage /> : <Navigate to="/404" />} />
        <Route path="/inbound" element={IN_OUT.includes(roleName) ? <InboundPage /> : <Navigate to="/404" />} />
        <Route path="/outbound">
          <Route index element={IN_OUT.includes(roleName) ? <OutboundPage /> : <Navigate to="/404" />} />
          <Route path="batch" element={IN_OUT.includes(roleName) ? <OutboundBatchPage /> : <Navigate to="/404" />} />
        </Route>
        <Route path="/report" element={REPORT.includes(roleName) ? <ReportPage /> : <Navigate to="/404" />} />
        <Route path="/setting">
          <Route index element={ALL_ACCESS.includes(roleName) ? <SettingPage /> : <Navigate to="/404" />} />
          <Route path="staff" element={ALL_ACCESS.includes(roleName) ? <StaffPage /> : <Navigate to="/404" />} />
          <Route path="role-management" element={ALL_ACCESS.includes(roleName) ? <RoleManagementPage /> : <Navigate to="/404" />} />
        </Route>
      </Route>
      <Route path="*" element={<LostPage />} />
    </Routes>
  );
}

export default App;
