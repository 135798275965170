/** @jsxImportSource @emotion/react */

import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Card, Col, Form, Select, Input, Row, Typography, Button, Space, Table, Modal, Tag, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Spacer from '../../components/layout/Spacer'
import ProductBrandFormModal from '../../components/product/ProductBrandFormModal'
import productBrandAPI from '../../services/productBrandAPI'
import { utcToLocal } from '../../utils/helper'
import { INITIAL_PAGINATION } from '../../utils/constant'

function ProductBrandPage() {
  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value) => <b>{value}</b>
    },

    {
      title: 'Status',
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      render: (val) => val ? <Tag color='red'>Inactive</Tag> : <Tag color='green'>Active</Tag>
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => <span>{utcToLocal(value)}</span>
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) => <Space >
        <Button type='link' size='small' onClick={() => selectData(record)}>Edit</Button>
        <Button type='link' size='small' onClick={() => deleteConfirm(value)}>Delete</Button>
      </Space >
    },
  ];

  const [modal, setModal] = useState(false)
  const [loading, setloading] = useState(false)
  const routeParams = useParams()

  const [productBrand, setProductBrand] = useState({
    data: [],
    selected: null,
  })

  const [pagination, setPagination] = useState(INITIAL_PAGINATION)

  useEffect(() => {
    fetchData(INITIAL_PAGINATION)
    setPagination(INITIAL_PAGINATION)
  }, [])

  const selectData = (record) => {
    setProductBrand({ ...productBrand, selected: record })
    setModal(true)
  }

  const deleteConfirm = (id) => {
    Modal.confirm({
      title: 'Do you Want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone',
      onOk: async () => {
        await productBrandAPI.remove(id)
        message.success('Delete success')
        fetchData({ current: 1, pageSize: 10 })
      },
    });
  }
  const fetchData = async (pagination, filters) => {
    setloading(true)
    const params = {
      ...filters,
      page: pagination.current,
      limit: pagination.pageSize,
    }

    const res = await productBrandAPI.getAllPaginate(params)
    setProductBrand({
      data: res.data.productBrand.items,
      selected: null,
    })
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: res.data.productBrand.meta.totalItems
    })
    setloading(false)
  }

  const submitFilter = (value) => {
    fetchData({ current: 1, pageSize: 10 }, value)
  }

  return (
    <div>
      <Typography.Title level={2}>Product Brand Management</Typography.Title>
      <Card>
        <Row justify='space-between'>
          <Col md={18}>
            <Form onFinish={submitFilter} layout='inline'>
              <Form.Item
                name="search"
                required
              >
                <Input placeholder='Cari data..' suffix={<SearchOutlined />} addonBefore={
                  <Select defaultValue="name">
                    <Select.Option value="name">Product Brand Name</Select.Option>
                  </Select>
                } />
              </Form.Item>


              <Button htmlType='submit' type='primary'>Search</Button>
            </Form>
          </Col>

          <Button onClick={() => setModal(true)} type='primary' >Add Product Brand</Button>
        </Row>

      </Card>
      <Spacer />
      <Card>
        <Table
          columns={tableColumns}
          pagination={pagination}
          onChange={(pagination, filter, sorter) => fetchData(pagination)}
          dataSource={productBrand.data}
          loading={loading}
          rowKey='id' scroll={{ x: true }}
        />
      </Card>

      <ProductBrandFormModal
        visible={modal}
        data={productBrand.selected}
        onCancel={() => {
          setModal(false)
          setProductBrand({ ...productBrand, selected: null })
        }}
        onFinish={() => {
          setProductBrand({ ...productBrand, selected: null })
          setModal(false)
          fetchData(pagination)
        }}
        warehouseId={routeParams.warehouseId}
      />
    </div>
  )
}
export default ProductBrandPage
