import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Row, Space, Button, Typography, Col, Table } from 'antd'
import Spacer from '../layout/Spacer'
import outboundAPI from '../../services/outboundAPI'
import OutboundStatusTag from '../common/OutboundStatusTag';

function OutboundBatchDetailModal({ visible, onCancel, data }) {
  const tableColumns = [
    {
      title: "External ID",
      dataIndex: "externalId",
      key: "externalId",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "List Product",
      dataIndex: "items",
      key: "items",
      render: (items) =>
        <ul style={{
          overflowY: 'scroll', paddingInlineStart: "20px", maxHeight: '100px'
        }} >
          {
            items.map((item) => (<li key={item.id}>
              <small> {item.product.sku} - {item.product.name} {item.quantity}X</small>
            </li>
            ))
          }
        </ul>
    },
    {
      title: "Warehouse",
      dataIndex: "warehouse",
      key: "warehouse",
      render: (value) => <span>{value.name}</span>
    },
    {
      title: "Source - Courier",
      dataIndex: "source",
      key: "source",
      render: (value, record) => <span>{value} - {record.delivery.courierName}</span>

    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => <OutboundStatusTag status={value} />

    },
    {
      title: "Total Quantity",
      dataIndex: "totalQuantity",
      key: "totalQuantity",
    },
  ];

  const [form] = Form.useForm()
  const [outboundItems, setOutboundItems] = useState([])

  const fetchOutboundDetail = async () => {
    const res = await outboundAPI.getOneBatch(data.id)
    form.setFieldsValue({ ...data })
    setOutboundItems(res.data.outboundBatch.outbounds)
  }

  useEffect(() => {
    form.resetFields()
    if (data) {
      fetchOutboundDetail()
    }
  }, [data, visible])

  return (
    <Modal title='Outbound Batch Detail' visible={visible} maskClosable={false} footer={false} onCancel={onCancel} width={970}>
      <Row>
        <Col md={24}>
          <Form
            form={form}
            layout='vertical'
          >
            <Row gutter={[8, 8]}>
              <Col md={12}>
                <Form.Item
                  label="ID"
                  name="id"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Status"
                  name="status"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Spacer />
      <Row>
        <Typography.Title level={5}>Outbound Batch Items</Typography.Title>
        <Table
          scroll={{ x: true }}
          columns={tableColumns}
          dataSource={outboundItems}
          pagination={false}
        />
      </Row>
      <Spacer />
      <Row justify='end'>
        <Space>
          <Button onClick={onCancel}>Close</Button>
        </Space>
      </Row>
    </Modal >
  )
}

export default OutboundBatchDetailModal
