import { baseAxios } from "./axios"


const create = async (body) => {
  const res = await baseAxios.post('/product', body)
  return res.data
}

const getAllPaginate = async (params = {}) => {
  const res = await baseAxios.get('/product', { params })
  return res.data
}

const getOne = async (id) => {
  const res = await baseAxios.get('/product/' + id)
  return res.data
}

const update = async (id, body) => {
  const res = await baseAxios.put('/product/' + id, body)
  return res.data
}

const remove = async (id) => {
  const res = await baseAxios.delete('/product/' + id)
  return res.data
}

const getHistory = async (id, params = {}) => {
  const res = await baseAxios.get(`/product/${id}/movement`, { params })
  return res.data
}

const getLocation = async (id, params = {}) => {
  const res = await baseAxios.get(`/product/${id}/location`, { params })
  return res.data
}

const createImport = async (body) => {
  const res = await baseAxios.post('/product/import', body)
  return res.data
}

const exports = async (params = {}) => {
  const res = await baseAxios.get('/product/export/excel', { params })
  return res.data
}

const exportCheck = async (body) => {
  const res = await baseAxios.post('/product/check/export/excel', body)
  return res.data
}

const exportLocation = async (params = {}) => {
  const res = await baseAxios.get('/product/location/excel', { params })
  return res.data
}

export default { create, update, getAllPaginate, getOne, remove, exportLocation, getHistory, getLocation, createImport, exports, exportCheck }