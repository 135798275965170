import { Tag } from 'antd'
import React from 'react'

function OutboundStatusTag({ status, count = false }) {
  return (
    status === 'pending' ? <Tag color='blue'>Pending {count && count}</Tag> :
      status === 'ready' ? <Tag color='orange'>Ready to Outbound {count && count}</Tag> :
        status === 'approved' ? <Tag color='green'>Approved {count && count}</Tag> :
          status === 'await packing' ? <Tag color='blue'>Await Packing {count && count}</Tag> :
            status === 'await shipment' ? <Tag color='cyan'>Await Shipment {count && count}</Tag> :
              status === 'shipped' ? <Tag color='green'>Shipped {count && count}</Tag> :
                status === 'understock' ? <Tag color='magenta'>Understock {count && count}</Tag> :

                  <Tag color='red'>Canceled {count && count}</Tag>
  )
}

export default OutboundStatusTag
