import axios from 'axios'
import _ from 'lodash'
import config from '../utils/config'
const getStorage = () => {
  const storage = JSON.parse(localStorage.getItem('persist:' + config.REDUX_PERSIST_KEY) || '{}')
  return _.isEmpty(storage) ? false : storage
}

const createBaseAxios = () => {
  const storage = getStorage()

  if (storage) {
    const auth = JSON.parse(storage.auth)

    return axios.create({
      baseURL: config.API_URL,
      headers: auth.token ? { 'Authorization': 'Bearer ' + auth.token } : {}
    })
  }
  // if first time open app no persist storage
  return axios.create(
    {
      baseURL: config.API_URL
    }
  )
}

const baseAxios = createBaseAxios()
// baseAxios.interceptors.response.use(response => response,
//   async error => {
//     if (error.response?.status === 401) {
//       await window.localStorage.removeItem('persist:' + config.REDUX_PERSIST_KEY);
//       window.location.replace("/login");
//       return Promise.reject(error);
//     }
//     return Promise.reject(error);
//   }
// );

const rawAxios = axios.create(
  {
    baseURL: config.API_URL
  }
)

export { baseAxios, rawAxios }