import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Row, Space, Button, Typography, Col, Table, Timeline } from 'antd'
import Spacer from '../layout/Spacer'
import { sumTotal, utcToLocal } from '../../utils/helper'

function InboundDetailModal({ visible, onCancel, data }) {
  const tableColumns = [
    {
      title: "Product Name",
      dataIndex: "product",
      key: "product",
      render: (value) => <b>{value.name}</b>
    },
    {
      title: "SKU",
      dataIndex: "product",
      key: "product",
      render: (value) => <span>{value.sku}</span>
    },
    {
      title: "Expected Quantity",
      dataIndex: "expectedQuantity",
      key: "expectedQuantity",
    },
    {
      title: "Received Quantity",
      dataIndex: "receivedQuantity",
      key: "receivedQuantity",
    },
    {
      title: "Put Quantity",
      dataIndex: "putQuantity",
      key: "putQuantity",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Status History",
      dataIndex: "statusHistory",
      key: "statusHistory",
      render: (value) => (
        <Timeline style={{ overflowY: 'scroll', maxHeight: '100px' }}>
          {value.map((history) => (
            <Timeline.Item>{history}</Timeline.Item>
          ))}
        </Timeline>
      )
    },
  ];

  const [form] = Form.useForm()
  const [inboundItems, setInboundItems] = useState([])

  useEffect(() => {
    form.resetFields()
    if (data) {
      form.setFieldsValue({
        ...data,
        code: data.code ?? data.id,
        quantity: `${data.totalQuantity}/${data.receivedQuantity}/${data.putQuantity}`,
        arriveAt: utcToLocal(data.arriveAt),
        expectedArriveAt: utcToLocal(data.arriveAt)
      })
      setInboundItems(data.inbounds)
    }
  }, [data, visible])

  return (
    <Modal title='Inbound Detail' visible={visible} maskClosable={false} footer={false} onCancel={onCancel} width={900}>
      <Row>
        <Col md={24}>
          <Form
            form={form}
            layout='vertical'
          >
            <Row gutter={[8, 8]}>
              <Col md={12}>
                <Form.Item
                  label="Code"
                  name="code"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Expected/Received/Put Quantity"
                  name="quantity"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Expected Arrive at"
                  name="expectedArriveAt"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Arrive at"
                  name="arriveAt"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Type"
                  name="type"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Status"
                  name="status"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Courier Name"
                  name="courierName"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Driver Name"
                  name="driverName"
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Spacer />
      <Row>
        <Typography.Title level={5}>Inbound Items</Typography.Title>
        <Table
          columns={tableColumns}
          dataSource={inboundItems}
          pagination={false}
        />
      </Row>
      <Spacer />
      <Row justify='end'>
        <Space>
          <Button onClick={onCancel}>Close</Button>
        </Space>
      </Row>
    </Modal >
  )
}

export default InboundDetailModal
