/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Table, Typography, message, Select } from "antd";
import SalesChart from "../components/dashboard/SalesChart";
import InboundChart from '../components/dashboard/InboundChart';
import OutboundChart from '../components/dashboard/OutboundChart';
import PieChartOutboundStatus from '../components/dashboard/PieChartOutboundStatus';
import PieChartInboundStatus from '../components/dashboard/PieChartInboundStatus';
import PieChartChannelsDistribution from '../components/dashboard/PieChartChannelsDistribution';
import Spacer from "../components/layout/Spacer";
import dayjs from 'dayjs';
import DatePicker from '../components/antdcustom/DatePicker';
import warehouseAPI from '../services/warehouseAPI';
import dashboardAPI from '../services/dashboardAPI';
import { currencyFormat } from '../utils/helper';
import config from '../utils/config';

function DashboardPage() {
  const topProductsColumns = [
    {
      title: 'Name',
      dataIndex: 'product_name',
      key: 'product_name',
      width: 300,
    },
    {
      title: 'Total Outbound (Pcs)',
      dataIndex: 'totalOutbound',
      key: 'totalOutbound',
    },
  ];

  const runningOutStocksColumns = [
    {
      title: 'Name',
      dataIndex: 'product_name',
      key: 'product_name',
      width: 300,
    },

    {
      title: 'Stocks',
      dataIndex: 'availableStock',
      key: 'availableStock',
    },
  ];

  const [loading, setLoading] = useState(false)
  const [warehouse, setWarehouse] = useState([])

  const [topProduct, setTopProduct] = useState([])

  const [outOfStock, setOutOfStock] = useState([])

  const [filters, setFilters] = useState({
    startDate: dayjs().add(config.APP_ENV == 'dev' ? -7 : -3, 'day').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  });

  const fetchWarehouse = async () => {
    setLoading(true)
    try {
      const res = await warehouseAPI.getAllPaginate({
        page: 1,
        limit: 1000,
      })
      setWarehouse(res.data.warehouse.items)
    } catch (error) {
      message.error('error fetch warehouse')
    }
    setLoading(false)
  }

  const fetchTopProduct = async () => {
    setLoading(true)
    let params = {
      ...filters,
    }
    const res = await dashboardAPI.getTopProduct(params)
    setTopProduct(res.data.outbound)
    setLoading(false)
  }

  const fetchOutOfStock = async () => {
    setLoading(true)
    let params = {
      ...filters,
    }
    const res = await dashboardAPI.getOutOfStock(params)
    setOutOfStock(res.data.stocks)
    setLoading(false)
  }

  const submitFilter = (value) => {
    const data = {
      ...value,
      startDate: value.date[0].format('YYYY-MM-DD'),
      endDate: value.date[1].format('YYYY-MM-DD'),
    }
    setFilters(data)
  };

  useEffect(() => {
    fetchWarehouse()
  }, [])

  useEffect(() => {
    fetchTopProduct()
    fetchOutOfStock()
  }, [filters])

  return (
    <div>
      <Typography.Title level={2}>Dashboard</Typography.Title>
      <Row gutter={[32, 32]}>
        <Col md={24}>
          <Card>
            <Form
              onFinish={submitFilter}
              layout="inline"
              initialValues={{ date: [dayjs().add(-3, 'day'), dayjs()] }}
            >
              <Form.Item name="date" label="Date">
                <DatePicker.RangePicker />
              </Form.Item>
              <Form.Item name="warehouseId" label="Warehouse">
                <Select placeholder="Select Warehouse">
                  {warehouse.map((item) => (
                    <Select.Option value={item.id}>{item.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Button htmlType="submit" type="primary">
                Search
              </Button>
            </Form>
          </Card>
        </Col>
        <Col md={24}>
          <Card title="Sales Chart">
            <SalesChart filters={filters} />
          </Card>
        </Col>
        <Col md={12}>
          <Card title="Inbound (pcs)">
            <InboundChart filters={filters} />
          </Card>
        </Col>
        <Col md={12}>
          <Card title="Outbound (pcs)">
            <OutboundChart filters={filters} />
          </Card>
        </Col>
        <Col md={12}>
          <Card title="Inbound Status">
            <PieChartInboundStatus filters={filters} />
          </Card>
        </Col>
        <Col md={12}>
          <Card title="Outbound Status">
            <PieChartOutboundStatus filters={filters} />
          </Card>
        </Col>
        <Col md={12}>
          <Card title="Channels Distribution">
            <PieChartChannelsDistribution filters={filters} />
          </Card>
        </Col>
        <Col md={12}>
          <Card title="Top 10 Products">
            <Spacer />
            <Table
              columns={topProductsColumns}
              bordered
              pagination={false}
              dataSource={topProduct}
              loading={loading}
              scroll={{ y: 240 }}
            />
          </Card>
        </Col>
        <Col md={12}>
          <Card title="Running Out of Stocks">
            <Spacer />
            <Table
              columns={runningOutStocksColumns}
              bordered
              // pagination={true}
              // onChange={(pagination, _filter, _sorter) => fetchInbound(pagination, filters)}
              dataSource={outOfStock}
              loading={loading}
              scroll={{ y: 240 }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default DashboardPage;
