/** @jsxImportSource @emotion/react */

import { Button, Card, Col, Form, Input, message, Row, Typography } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import userAPI from '../services/userAPI'
import { authAction } from '../redux/reducers/authReducer'
import { Link } from 'react-router-dom'
import { Styles } from '../styles'
import images from '../assets/images'

function LoginPage() {
  const dispatch = useDispatch()

  const [loading, setloading] = useState(false)
  const login = async (value) => {
    try {
      const res = await userAPI.login(value)
      const { user, token } = res.data
      dispatch(authAction.login({
        user: user,
        token: token,
      }))
    } catch (error) {
      message.error(error.response.data.message)
    }
  }
  return (
    <Row justify='center'>
      <Col md={10}>
        <div css={Styles.textCenter}>
          <img src={images.adawmsLogo} width='30%' />
        </div>
        <Card>
          <Typography.Title level={2}>Login</Typography.Title>

          <Form layout='vertical' initialValues={{ remember: true }} onFinish={login}>
            <Form.Item
              name='email'
              label='Email'
              rules={[{ required: true, message: 'Email required' }]}
            >
              <Input size='large' />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Password required' }]}
            >
              <Input.Password size='large' />
            </Form.Item>


            <Form.Item>
              <Button size='large' type="primary" htmlType="submit" block loading={loading}>
                Login
              </Button>
            </Form.Item>
          </Form>
          <div css={Styles.textCenter}>
            Dont have an account? <Link to='/register'>Register here</Link>
          </div>

        </Card>
      </Col>
    </Row>
  )
}

export default LoginPage
