import { blue, green, grey, magenta, red, orange, volcano, yellow, lime, cyan, geekblue, purple, gold, generate, presetPalettes } from '@ant-design/colors';

const Colors = {
  blue: blue,
  green: green,
  grey: grey,
  magenta: magenta,
  red: red,
  orange: generate('#FF6833'),
  volcano: volcano,
  yellow: yellow,
  lime: lime,
  cyan: cyan,
  geekblue: geekblue,
  purple: purple,
  gold: gold,
  neutral: generate('#bfbfbf')
}


export default Colors