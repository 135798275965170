import Size from "./Size"

const Padding = {
  p1: {
    padding: Size.sm
  },
  p2: {
    padding: Size.md
  },
  p3: {
    padding: Size.lg
  },
  p4: {
    padding: Size.xl
  },
  p5: {
    padding: Size.xxl
  },
  // px
  px1: {
    paddingLeft: Size.sm,
    paddingRight: Size.sm
  },
  px2: {
    paddingLeft: Size.md,
    paddingRight: Size.md
  },
  px3: {
    paddingLeft: Size.lg,
    paddingRight: Size.lg
  },
  px4: {
    paddingLeft: Size.xl,
    paddingRight: Size.xl
  },
  // py
  py0: {
    paddingTop: 0,
    paddingBottom: 0
  },
  py1: {
    paddingTop: Size.sm,
    paddingBottom: Size.sm
  },
  py2: {
    paddingTop: Size.md,
    paddingBottom: Size.md
  },
  py3: {
    paddingTop: Size.lg,
    paddingBottom: Size.lg
  },
  py4: {
    paddingTop: Size.xl,
    paddingBottom: Size.xl
  },
  py5: {
    paddingTop: Size.xxl,
    paddingBottom: Size.xxl
  },
  // pl
  pl1: {
    paddingLeft: Size.sm
  },
  pl2: {
    paddingLeft: Size.md
  },
  pl3: {
    paddingLeft: Size.lg
  },
  pl4: {
    paddingLeft: Size.xl
  },
  // pr
  pr1: {
    paddingRight: Size.sm
  },
  pr2: {
    paddingRight: Size.md
  },
  pr3: {
    paddingRight: Size.lg
  },
  pr4: {
    paddingRight: Size.xl
  },
}
export default Padding