import { UploadOutlined } from '@ant-design/icons'
import { Modal, Form, Input, message, Row, Space, Button, Select, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import inboundAPI from '../../services/inboundAPI'
import warehouseAPI from '../../services/warehouseAPI'
import config from '../../utils/config'
import DatePicker from '../antdcustom/DatePicker'
import dayjs from 'dayjs'

function InboundFormModal({ visible, onCancel, onFinish, brand, data }) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [warehouse, setWarehouse] = useState([])
  const auth = useSelector(state => state.auth)

  useEffect(() => {
    form.resetFields()
    fetchWarehouse()
    if (data) {
      form.setFieldsValue({
        ...data,
        brandId: data.inbounds[0].product.brand.id,
        warehouseId: data.inbounds[0].warehouse.id,
        expectedArriveAt: dayjs(data.expectedArriveAt)
      });
    }
  }, [data, visible])

  const submitForm = async (value) => {
    setLoading(true)
    let body = value
    if (value.files) {
      const file = {
        name: value.files[0].response.data.Key,
        url: value.files[0].response.data.Location
      }
      body = { ...value, file }
    }
    try {
      if (data) {
        await inboundAPI.update(data.id, body)
      } else {
        await inboundAPI.create(body)
      }
      message.success('success')
      onFinish()
    } catch (error) {
      message.error(error.response.data.message)
    }

    setLoading(false)
  }

  const fetchWarehouse = async () => {
    setLoading(true)
    try {
      const res = await warehouseAPI.getAllPaginate({
        page: 1,
        limit: 1000,
      })
      setWarehouse(res.data.warehouse.items)
    } catch (error) {
      message.error('error fetch warehouse')
    }
    setLoading(false)
  }

  const onChangeUpload = ({ fileList, file }) => {
    if (file.status == 'uploading') {
      setLoading(true)
    }
    else if (file.status == 'done') {
      setLoading(false)
    }
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return (
    <Modal
      title={data ? 'Edit Inbound' : 'Create Inbound'}
      visible={visible}
      footer={false}
      maskClosable={false}
      onCancel={onCancel}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={submitForm}
        autoComplete="off"
      >
        <Form.Item
          label="Batch Code"
          name="code"
          rules={[{
            max: 5,
            message: 'Max 5 characters'
          }]}
        >
          <Input placeholder='Leave empty to auto generate' />
        </Form.Item>

        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true }]}
        >
          <Select>
            <Select.Option value='register'>
              Register
            </Select.Option>
            <Select.Option value='return'>
              Return
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Brand"
          name="brandId"
          rules={[{ required: true }]}
        >
          <Select>
            {brand.map(item =>
              <Select.Option value={item.id}>
                {item.name}
              </Select.Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item
          label="Warehouse"
          name="warehouseId"
          rules={[{ required: true }]}
        >
          <Select>
            {warehouse.map(item =>
              <Select.Option value={item.id}>
                {item.name}
              </Select.Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          name="expectedArriveAt"
          label="Expected Arrival Date"
          rules={[{ required: true }]}
        >
          <DatePicker format='YYYY/MM/DD' />
        </Form.Item>

        <Form.Item
          name='files'
          label='File'
          getValueFromEvent={normFile}
          valuePropName="fileList"
          rules={[{ required: data ? false : true }]}
        >
          <Upload
            maxCount={1}
            action={config.API_URL + '/file/upload?folder=inbound'}
            headers={{ Authorization: 'Bearer ' + auth.token }}
            type='drag'
            onChange={onChangeUpload}>
            <UploadOutlined /> Click or drag files to this area to upload
          </Upload>
        </Form.Item>
        <Button href={`${config.AWS_S3_URL}/template/adawms-inbound-template.xlsx`}>
          Download template file
        </Button>
        <Row justify='end'>
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType='submit' loading={loading} type='primary' >Save</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default InboundFormModal
