import { Modal, Form, Input, Switch, message, Row, Space, Button, Select, Typography, Col, notification, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import shelfAPI from '../../services/shelfAPI'
import { useSelector } from 'react-redux'
import config from '../../utils/config'
import { UploadOutlined } from '@ant-design/icons'

function ShelfImportModal({ visible, onCancel, rack, onFinish }) {
  const auth = useSelector(state => state.auth)

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    form.resetFields()
  }, [visible])

  const submitForm = async (value) => {
    setLoading(true)
    try {
      const res = await shelfAPI.createImport({
        ...value,
        file: {
          name: value.files[0].response.data.Key,
          url: value.files[0].response.data.Location
        }
      })

      notification.info({
        duration: null,
        message: `Import Done`,
        placement: 'top',
      });
      setLoading(false)
    } catch (error) {
      notification.error({
        message: `Import Failed`,
        placement: 'top',
        duration: 10,
        description: error.response.data.message,
      });
      setLoading(false)

    }
    setLoading(false)
    onFinish()
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const onChangeUpload = ({ fileList, file }) => {
    if (file.status == 'uploading') {
      setLoading(true)
    }
    else if (file.status == 'done') {
      setLoading(false)
    }
  }

  return (
    <Modal title={'Import Shelf'} maskClosable={false} visible={visible} footer={false} onCancel={onCancel}>
      <Form
        form={form}
        layout='vertical'
        onFinish={submitForm}
        autoComplete="off"
      >

        <Form.Item
          label='Rack Name'
          initialValue={rack?.code}
        >
          <Input readOnly value={rack?.code} />
        </Form.Item>
        <Form.Item
          name="rackId"
          initialValue={rack?.id}
          hidden
        >
        </Form.Item>

        <Form.Item
          name='files'
          label='File'
          getValueFromEvent={normFile}
          valuePropName="fileList"
          rules={[{ required: true }]}
        >
          <Upload
            maxCount={1}
            action={config.API_URL + '/file/upload?folder=shelf'}
            headers={{ Authorization: 'Bearer ' + auth.token }}
            type='drag'
            onChange={onChangeUpload}>
            <UploadOutlined /> Click or drag files to this area to upload
          </Upload>
        </Form.Item>

        <Button href={`${config.AWS_S3_URL}/template/adawms-shelf-template.xlsx`}>
          Download template file
        </Button>

        <Row justify='end'>
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType='submit' loading={loading} type='primary' >Save</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default ShelfImportModal
