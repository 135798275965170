import React, { useState, useEffect } from 'react';
import { Pie, G2 } from '@ant-design/charts';
import dashboardAPI from '../../services/dashboardAPI';


const PieChartChannelsDistribution = ({ filters }) => {
  const G = G2.getEngine('canvas');

  const [data, setData] = useState([])

  const fetchData = async () => {
    let params = {
      ...filters,
    }
    const res = await dashboardAPI.getChannelsDistribution(params)
    res.data.outbound = res.data.outbound.map((item) => {
      item.total = parseInt(item.total);
      return item;
    });
    setData(res.data.outbound)
  }

  useEffect(() => {
    fetchData(filters);
  }, [filters]);

  const config = {
    appendPadding: 1,
    data,
    angleField: 'total',
    colorField: 'outbound_source',
    radius: 0.5,
    legend: false,
    label: {
      type: 'spider',
      labelHeight: 40,
      formatter: (data, mappingData) => {
        const group = new G.Group({});
        group.addShape({
          type: 'circle',
          attrs: {
            x: 0,
            y: 0,
            width: 40,
            height: 50,
            r: 5,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: 'text',
          attrs: {
            x: 10,
            y: 8,
            text: `${data.outbound_source}`,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: 'text',
          attrs: {
            x: 0,
            y: 25,
            text: `${data.total} (${(data.percent * 100).toFixed(0)}%)`,
            fill: 'rgba(0, 0, 0, 0.65)',
            fontWeight: 700,
          },
        });
        return group;
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  return <Pie {...config} />;
};

export default PieChartChannelsDistribution;
