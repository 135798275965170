import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Row, Space, Button, message } from 'antd'
import { utcToLocal } from '../../utils/helper'
import stockAPI from '../../services/stockAPI'

function StockOpnameApproveModal({ visible, onCancel, data, onFinish }) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const submitForm = async (value, body) => {
    setLoading(true)
    try {
      await stockAPI.updateStockOpnameStatus(data.id, value)
      message.success('success')
    } catch (error) {
      message.error('something wrong')
    }
    setLoading(false)
    onFinish()
  }

  useEffect(() => {
    form.resetFields()
    if (data) {
      form.setFieldsValue({
        ...data,
        shelf: data.shelf.code,
      })
    }
  }, [data, visible])

  return (
    <Modal title='Approve Stock Opname' maskClosable={false} visible={visible} footer={false} onCancel={onCancel}>
      <Form
        form={form}
        layout='vertical'
        // onFinish={submitForm}
        autoComplete="off"
      >
        <Form.Item
          label="Shelf"
          name="shelf"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Actual Quantity"
          name="actualQuantity"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Expected Quantity"
          name="expectedQuantity"
        >
          <Input readOnly />
        </Form.Item>
        {/* <Form.Item 
          label="Note" 
          name="note" 
        >
          <Input />
        </Form.Item>
        <Row justify='end'>
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType='submit' loading={loading} type='primary'>Save</Button>
          </Space>
        </Row> */}
      </Form>
      <Form
        layout='vertical'
        onFinish={submitForm}
        autoComplete="off"
      >
        <Form.Item
          label="Note"
          name="note"
        >
          <Input />
        </Form.Item>
        <Row justify='end'>
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType='submit' loading={loading} type='primary'>Save</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default StockOpnameApproveModal
