/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Button, Card, Col, Form, Row, Statistic, Select, Table, message, Typography } from "antd";
import Spacer from "../../components/layout/Spacer";
import DatePicker from "../../components/antdcustom/DatePicker";
import { utcToLocal } from "../../utils/helper";
import dayjs from 'dayjs';
import reportAPI from "../../services/reportAPI";
import warehouseAPI from "../../services/warehouseAPI";
import productBrandAPI from "../../services/productBrandAPI"
import { Theme } from "../../styles";

function ReportPage() {
  const orderColumns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => <span>{utcToLocal(value, 'date')}</span>,
    },
    {
      title: 'Pending',
      dataIndex: 'pending',
      key: 'pending',
    },
    {
      title: 'Canceled',
      dataIndex: 'canceled',
      key: 'canceled',
    },
    {
      title: 'Understock',
      dataIndex: 'understock',
      key: 'understock',
    },
    {
      title: 'Ready to Outbound',
      dataIndex: 'ready',
      key: 'ready',
    },
    {
      title: 'Ready to Pack',
      dataIndex: 'awaitPacking',
      key: 'awaitPacking',
    },
    {
      title: 'Await Shipment',
      dataIndex: 'awaitShipment',
      key: 'awaitShipment',
    },
    {
      title: 'Shipped',
      dataIndex: 'shipped',
      key: 'shipped',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
  ];

  const [loading, setLoading] = useState(false);
  // const [pagination, setPagination] = useState({
  //   current: 1,
  //   pageSize: 10,
  //   total: 0,
  // });
  const [filters, setFilters] = useState({
    startDate: dayjs().add(-1, 'day').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  });
  const [reportToday, setReportToday] = useState({
    inbound: 0,
    outbound: 0
  });
  const [reportStatus, setReportStatus] = useState([])
  const [productBrand, setProductBrand] = useState([]);
  const [warehouse, setWarehouse] = useState([])

  const fetchTodayReport = async (filters) => {
    setLoading(true)
    let params = {
      ...filters,
    };
    const res = await reportAPI.getReportToday(params)

    const parseInbound = parseInt(res.inbound.total)
    const parseOutbound = parseInt(res.outbound.total)

    setReportToday({
      inbound: parseInbound,
      outbound: parseOutbound
    })
    setLoading(false)
  }

  const fetchReportstatus = async (filters) => {
    setLoading(true)
    let params = {
      ...filters,
    };
    const res = await reportAPI.getReportStatus(params)
    setReportStatus(res.data.outbound)
    setLoading(false)
  }

  const exportReportstatus = async (filters) => {
    setLoading(true)
    let params = {
      ...filters,
    };
    const res = await reportAPI.exportReportStatus(params)
    window.location.href = res.data.outbound.Location
    setLoading(false)
  }

  const fetchBrand = async () => {
    setLoading(true)
    const params = {
      page: 1,
      limit: 1000,
    }
    const res = await productBrandAPI.getAllPaginate(params)
    setProductBrand(res.data.productBrand.items,)
    setLoading(false)
  }

  const fetchWarehouse = async () => {
    setLoading(true)
    try {
      const res = await warehouseAPI.getAllPaginate({
        page: 1,
        limit: 1000,
      })
      setWarehouse(res.data.warehouse.items)
    } catch (error) {
      message.error('error fetch warehouse')
    }
    setLoading(false)
  }

  const submitFilter = (value) => {
    const data = {
      ...value,
      startDate: value.date[0].format('YYYY-MM-DD'),
      endDate: value.date[1].format('YYYY-MM-DD'),
    }
    setFilters(data)
  };

  useEffect(() => {
    fetchBrand();
    fetchWarehouse()
  }, [])

  useEffect(() => {
    fetchTodayReport(filters);
    fetchReportstatus(filters)
  }, [filters]);

  return (
    <div>
      <Typography.Title level={2}>Report</Typography.Title>
      <Row gutter={12}>
        <Col md={12}>
          <Card css={styles.card(Theme.lightBlue)}>
            <Statistic title="Today's Inbound" value={reportToday.inbound} />
          </Card>
        </Col>
        <Col md={12}>
          <Card css={styles.card(Theme.lightGreen)}>
            <Statistic title="Today's Outbound" value={reportToday.outbound} />
          </Card>
        </Col>
      </Row>
      <Spacer />
      <Row>
        <Col md={24}>
          <Card
            title='Order Status'
          >
            <Row justify='space-between'>
              <Col md={20}>
                <Form
                  onFinish={submitFilter}
                  layout="inline"
                  initialValues={{ date: [dayjs().add(-1, 'day'), dayjs()] }}
                >
                  <Form.Item name="date" label="Inbound Date">
                    <DatePicker.RangePicker />
                  </Form.Item>
                  <Form.Item name="brandId" label="Brand">
                    <Select placeholder="Select Brand">
                      {productBrand.map((item) => (
                        <Select.Option value={item.id}>{item.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="warehouseId" label="Warehouse">
                    <Select placeholder="Select Warehouse">
                      {warehouse.map((item) => (
                        <Select.Option value={item.id}>{item.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                </Form>
              </Col>
              <Button loading={loading} type="primary" onClick={() => exportReportstatus(filters)}>
                Export
              </Button>
            </Row>
            <Spacer />
            <Table
              columns={orderColumns}
              // pagination={pagination}
              dataSource={reportStatus}
              loading={loading}
              scroll={{ x: true }}
            />
          </Card>
        </Col>
      </Row>
      <Spacer />
    </div>
  );
}

const styles = {
  card: (background) =>
    css({
      borderTop: `4px solid ${background}`,
    }),
};
export default ReportPage;
