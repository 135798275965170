import React from 'react'
import images from '../../assets/images'
import { Size } from '../../styles'

export const ChannelIcon = ({ channelName }) => {
  var src = images.tags
  if (channelName.includes('tiktok')) {
    src = images.tiktok
  }
  else if (channelName.includes('shopee')) {
    src = images.shopee
  }
  else if (channelName.includes('toko')) {
    src = images.tokopedia
  }
  else if (channelName.includes('lazada')) {
    src = images.lazada
  }


  return <img src={src} style={{ width: Size.lg }} />
}
