/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { SearchOutlined } from '@ant-design/icons'
import { Card, Col, Form, Input, Row, Typography, Button, Space, Table, Modal, message } from 'antd'
import Spacer from '../../components/layout/Spacer'
import RoleFormModal from "../../components/setting/RoleFormModal";
import roleAPI from '../../services/roleAPI';

function RoleManagementPage() {
  const tableColumns = [
    {
      title: 'Role Name',
      dataIndex: 'name',
      key: 'name',
      render: (value) => <b>{value}</b>
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    // {
    //   title: "Action",
    //   dataIndex: "id",
    //   key: "id",
    //   render: (value, record) => (
    //     <Space>
    //       <Button type="link" size="small" onClick={() => selectData(record)}>
    //         Edit
    //       </Button>
    //       <Button type="link" size="small" onClick={() => deleteData(value)} >
    //         Delete
    //       </Button>
    //     </Space>
    //   ),
    // },
  ];

  const [modal, setModal] = useState(false)
  const [loading, setloading] = useState(false)
  const [role, setRole] = useState({
    data: [],
    selected: null,
  })

  useEffect(() => {
    fetchData()
  }, [])

  // const selectData = (record) => {
  //   setRole({ ...role, selected: record })
  //   setModal(true)
  // }
  // const deleteData = (id) => {
  //   Modal.confirm({
  //     title: "Do you Want to delete this role?",
  //     icon: <ExclamationCircleOutlined />,
  //     content: "This action cannot be undone",
  //     onOk: async () => {
  //       await roleAPI.deleteRole(id);
  //       message.success("Delete success");
  //       fetchData({ current: 1, pageSize: 10 });
  //     },
  //   });
  // };

  const fetchData = async (pagination, filters) => {
    setloading(true)
    const params = {
      ...filters,
    }
    const res = await roleAPI.getAllRolePaginate(params)
    setRole({
      data: res.data.data.items,
      selected: null,
    })
    setloading(false)
  }

  const submitFilter = (value) => {
    fetchData({ current: 1, pageSize: 10 }, value)
  }

  return (
    <div>
      <Typography.Title>Role Management</Typography.Title>
      <Card>
        <Row justify='space-between'>
          <Col md={18}>
            <Form onFinish={submitFilter} layout='inline'>
              <Form.Item
                name="search"
                required
              >
                <Input placeholder='Cari role..' suffix={<SearchOutlined />}/>
              </Form.Item>
              <Button htmlType='submit' type='primary'>Search</Button>
            </Form>
          </Col>
          {/* <Button onClick={() => setModal(true)} type='primary'>Add Role</Button> */}
        </Row>
      </Card>
      <Spacer />
      <Card>
        <Table
          columns={tableColumns}
          onChange={(filter, sorter) => fetchData()}
          dataSource={role.data}
          loading={loading}
          rowKey='id' scroll={{ x: true }}
        />
      </Card>

      <RoleFormModal
        visible={modal}
        data={role.selected}
        onCancel={() => {
          setModal(false)
          setRole({ ...role, selected: null })
        }}
        onFinish={() => {
          setRole({ ...role, selected: null })
          setModal(false)
          fetchData()
        }}
      />
    </div>
  )
}

export default RoleManagementPage