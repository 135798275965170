import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import config from '../config';
import logger from 'redux-logger'
import { configureStore } from '@reduxjs/toolkit'


const persistConfig = {
  key: config.REDUX_PERSIST_KEY,
  storage,
  whitelist: ['auth']
}

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
})
const persistor = persistStore(store)
export default { store, persistor }


  